import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

const SummaryEmptyStateWrapper: React.FC<BoxProps> = ({
  children,
  ...boxProps
}) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDir="column"
      h="100%"
      width="100%"
      pb="4"
      gap="4"
      overflow="auto"
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default SummaryEmptyStateWrapper;
