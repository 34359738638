import useFeatureFlag from "../../../../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../../../../hooks/useCurrentUser";

export const useAsk2Enabled = (): boolean => {
  const currentUser = useCurrentUser();
  const orgSettingEnabled = Boolean(currentUser?.organization?.askEnabled);

  const flagEnabled = useFeatureFlag("interview_ask:v2");
  // To launch to candidate summary beta customers:
  // - also check flagEnabled against useFeatureFlag('candidate_page:v2')

  return Boolean(orgSettingEnabled && flagEnabled);
};
