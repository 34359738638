import { Box, Center, Text, VStack } from "@chakra-ui/react";
import React from "react";

import AILoading from "../../../components/LoadingIndicator/AILoading";

export interface AnalyticsKeyThemesProps {
  summaryLoading: boolean;
  summary: {
    id: string;
    themeName: string;
    summary: string;
  }[];
}

const AnalyticsKeyThemes: React.FC<AnalyticsKeyThemesProps> = ({
  summaryLoading,
  summary,
}) => {
  return (
    <>
      <Text
        mb="2"
        color="gray.500"
        fontWeight="600"
        textTransform="uppercase"
        fontSize="xs"
      >
        Key Themes
      </Text>
      <Box bg="gray.50" p="4" mb="6" borderRadius="8px">
        {summaryLoading && (
          <Center>
            <VStack>
              <AILoading style={{ width: 100, height: 100 }} />
              <Text color="gray.500" fontSize="sm" fontWeight="400">
                Loading key themes...
              </Text>
            </VStack>
          </Center>
        )}
        {!summaryLoading && summary && summary.length === 0 && (
          <Text color="gray.500" fontSize="sm" fontWeight="400">
            No key themes for the selected data.
          </Text>
        )}
        {!summaryLoading &&
          summary &&
          summary.map((theme) => (
            <Box key={theme.id} mb="3" _last={{ mb: 0 }}>
              <Text
                as="div"
                fontSize="sm"
                fontWeight="600"
                color="gray.800"
                mb="1"
              >
                {theme.themeName}
              </Text>
              <Text color="gray.800" fontSize="sm" fontWeight="400">
                {theme.summary}
              </Text>
            </Box>
          ))}
      </Box>
    </>
  );
};

export default AnalyticsKeyThemes;
