import React from "react";
import { Navigate } from "react-router-dom";

import { useOrgAccessMap } from "../../../hooks/useOrgFeatureAccess";
import { FeatureName } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

type FeatureAccessProps = {
  feature: FeatureName;
  children: JSX.Element;
  redirectRoute?: string;
};

const FeatureAccess: React.FC<FeatureAccessProps> = ({
  feature,
  redirectRoute,
  children,
}) => {
  const currentUser = useCurrentUser();
  const accessMap = useOrgAccessMap(currentUser);
  const orgAccess = accessMap?.get(feature);

  if (!orgAccess?.isVisible) {
    return <Navigate to={redirectRoute || "/not-found"} replace />;
  }
  return children;
};

export default FeatureAccess;
