import { Box, Tooltip, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { IoArrowRedoOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

import {
  errorToast,
  IconButton,
  successToast,
  useToast,
} from "../../../../components";
import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import {
  CallBetaFragment,
  useUpdateCallCandidateMutation,
  useUpdateCallMutation,
  useUpdateCallPositionMutation,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import CallStarRating from "../../CallStarRating";
import { CandidateLinks, DetailsHeader } from "../../Recording";
import { ShareCallButton } from "../../ShareCallButton";
import EditCallDetailsModal, {
  EditCallDetailField,
} from "./EditCallDetailsModal";
import SetLanguageModal from "./SetLanguageModal";
import useCallMenuOptions from "./useCallMenuOptions";
import useCandidateLinks from "./useCandidateLinks";

type CallDetailsHeaderProps = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "candidate"
    | "position"
    | "startTime"
    | "name"
    | "status"
    | "rating"
    | "interviewers"
    | "interviewerIds"
    | "viewers"
    | "externalViewers"
    | "visibility"
    | "visibilityLevels"
    | "canEdit"
    | "canDelete"
    | "canShare"
    | "canShareExternal"
    | "skipRedaction"
  >;
} & {
  actionButtons?: JSX.Element;
};

/**
 * Wraps Recording/DetailsHeader component and manages the edit modal
 */
const CallDetailsHeader: React.FC<CallDetailsHeaderProps> = ({
  call,
  actionButtons,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const setPath = (path: string): void =>
    navigate(
      {
        pathname: `/interview/${call.id}${path}`,
        search: window.location.search,
      },
      { replace: true }
    );

  const [focusedEditField, setFocusedEditField] =
    useState<EditCallDetailField | null>(null);

  const [updateCall] = useUpdateCallMutation({
    onError: () =>
      errorToast(toast, "There was a problem updating this interview"),
  });
  const [updateCallPosition] = useUpdateCallPositionMutation({
    onError: () =>
      errorToast(toast, "There was a problem updating this position"),
  });
  const [updateCallCandidate] = useUpdateCallCandidateMutation({
    onError: () =>
      errorToast(toast, "There was a problem updating this candidate"),
  });

  const onUpdate = async ({
    candidateId,
    positionId,
    name,
  }: Record<any, string | undefined>): Promise<void> => {
    const positionChanged = positionId !== call.position?.id;
    const candidateChanged = candidateId !== call.candidate?.id;
    const nameChanged = call.name ? name !== call.name : !!name;

    const updates: Promise<{ errors?: unknown }>[] = [];

    if (positionChanged) {
      updates.push(
        updateCallPosition({
          variables: { id: call.id, positionId: positionId ?? "" },
        })
      );
    }

    if (candidateChanged) {
      updates.push(
        updateCallCandidate({
          variables: { callId: call.id, candidateId },
        })
      );
    }

    if (nameChanged) {
      updates.push(
        updateCall({
          variables: { id: call.id, name },
        })
      );
    }

    const results = await Promise.all(updates);
    if (results.length && results.every(({ errors }) => !errors)) {
      successToast(toast, "Interview updated");
    }
  };

  const showStarRating = currentUser.organization.showStarRatings ?? false;
  const candidateLinks = useCandidateLinks(call);

  const editModal = useDisclosure({
    isOpen: location.pathname.endsWith("/edit"),
    onOpen: () => setPath("/edit"),
    onClose: () => setPath(""),
  });

  const setLanguageModal = useDisclosure({
    isOpen: location.pathname.endsWith("/set-language"),
    onOpen: () => setPath("/set-language"),
    onClose: () => setPath(""),
  });

  const menuOptions = useCallMenuOptions(call, {
    onEdit: editModal.onOpen,
    onSetLanguage: setLanguageModal.onOpen,
  });

  const shareModal = useDisclosure({
    isOpen: location.pathname.endsWith("/share"),
    onOpen: () => setPath("/share"),
    onClose: () => setPath(""),
  });

  return (
    <Box>
      <DetailsHeader
        candidate={call.candidate}
        position={call.position}
        interviewers={call.interviewers}
        title={call.name}
        date={call.startTime}
        viewers={call.viewers}
        externalViewers={call.externalViewers}
        titleSlot={
          isSmallScreen ? null : showStarRating ? (
            <Box mt="2">
              <CallStarRating call={call} />
            </Box>
          ) : (
            <CandidateLinks {...candidateLinks} />
          )
        }
        canEdit={call.canEdit}
        onEdit={(field) => {
          setFocusedEditField(field);
          editModal.onOpen();
        }}
        share={
          call.canShare || call.canShareExternal ? (
            <ShareCallButton
              callId={call.id}
              positionId={call.position?.id}
              callVisibility={call.visibility}
              visibilityLevels={call.visibilityLevels}
              readOnly={!call.canEdit}
              isModalOpen={shareModal.isOpen}
              onOpenModal={shareModal.onOpen}
              onCloseModal={shareModal.onClose}
            >
              <Tooltip label="Share Interview" openDelay={1000}>
                <IconButton
                  icon={<IoArrowRedoOutline size="20" />}
                  aria-label="Share Interview"
                  data-testid="share-button"
                  color="blue.600"
                  variant="white"
                  minW="8"
                  h="8"
                  ml="2"
                />
              </Tooltip>
            </ShareCallButton>
          ) : undefined
        }
        menuOptions={menuOptions}
        actionButtons={actionButtons}
      />

      {editModal.isOpen && (
        <EditCallDetailsModal
          call={call}
          focusedField={focusedEditField}
          onClose={editModal.onClose}
          onUpdate={({ candidate, position, name }) =>
            onUpdate({
              candidateId: candidate?.id,
              positionId: position?.id,
              name,
            })
          }
        />
      )}
      {setLanguageModal.isOpen && (
        <SetLanguageModal call={call} onClose={setLanguageModal.onClose} />
      )}
    </Box>
  );
};

export default CallDetailsHeader;
