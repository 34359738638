import { useToast } from "@chakra-ui/react";
import React from "react";

import { successToast } from "../../../../components";
import {
  CallBetaFragment,
  CallNoteType,
  useCallShareQuery,
} from "../../../graphql";
import VideoBeta, { VideoBetaProps } from "../VideoBeta";
import useCallNotesV3 from "./useCallNotesV3";
import useCallTranscript from "./useCallTranscript";

type CallVideoProps = Omit<
  VideoBetaProps,
  | "speakers"
  | "duration"
  | "onAddNote"
  | "notes"
  | "segments"
  | "shareableUsers"
  | "shareableUsersLoading"
> & {
  call: CallBetaFragment;
  notesForCurrentUserOnly: boolean;
};

const CallVideo: React.FC<CallVideoProps> = ({
  call,
  notesForCurrentUserOnly,
  ...videoProps
}) => {
  const toast = useToast();

  const { onAddNote } = useCallNotesV3({
    call,
    notesForCurrentUserOnly,
    onAddCallNoteCompleted(data) {
      const note = data.addNewCallNote?.callNote;
      if (note) {
        successToast(
          toast,
          `${note.type === CallNoteType.Note ? "Note" : "Reaction"} added`,
          { position: "top" }
        );
      }
    },
  });

  const { data: transcriptData } = useCallTranscript({
    callId: call.id,
    notesForCurrentUserOnly,
  });
  const { notesToHighlight: notes } = transcriptData;

  const shareQuery = useCallShareQuery({ variables: { id: call.id } });

  return (
    <VideoBeta
      {...videoProps}
      callId={call.id}
      speakers={call.speakers}
      canChangeSpeaker={call.canEdit}
      duration={call.duration}
      onAddNote={onAddNote}
      notes={notes ?? []}
      segments={transcriptData.transcript}
      shareableUsers={shareQuery.data?.call?.shareableUsers}
      shareableUsersLoading={shareQuery.loading}
    />
  );
};

export default CallVideo;
