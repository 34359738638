/** Human readable description of the remaining duration of an external share */
export const remainingShareDuration = (remaining: number): string => {
  if (remaining < 0) return "Access expired";
  if (remaining < 1) {
    const roundedHours = Math.round(remaining * 24);
    if (roundedHours <= 1) return "Access expires in the next hour";
    return `Access expires in ${roundedHours} hours`;
  }
  const rounded = Math.round(remaining);
  return `Access expires in ${rounded} day${rounded > 1 ? "s" : ""}`;
};

export const EXTERNAL_SHARE_DURATION_VALUES = [
  { value: 0.125, name: "3 hours" },
  { value: 0.25, name: "6 hours" },
  { value: 0.5, name: "12 hours" },
  { value: 1, name: "1 day" },
  { value: 5, name: "5 days" },
  { value: 7, name: "7 days" },
  { value: 14, name: "14 days" },
  { value: 21, name: "21 days" },
  { value: 28, name: "28 days" },
];
