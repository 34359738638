import { useEffect } from "react";

import {
  useCallNotesV3Query,
  useCallTranscriptBetaQuery,
} from "../../../graphql";
import { TranscriptProps } from "../../Recording";

type CallTranscriptParams = {
  callId: string;
  notesForCurrentUserOnly: boolean;
};

type CallTranscriptData = Pick<
  TranscriptProps,
  "transcript" | "notesToHighlight" | "loading" | "error"
>;

type CallTranscriptReturn = {
  data: CallTranscriptData;
  stopPolling(): void;
};

const useCallTranscript = ({
  callId,
  notesForCurrentUserOnly,
}: CallTranscriptParams): CallTranscriptReturn => {
  const { data, loading, error, stopPolling } = useCallTranscriptBetaQuery({
    variables: { callId },
    pollInterval: 5000,
  });

  const { data: callNotesData } = useCallNotesV3Query({
    variables: { id: callId, notesForCurrentUserOnly },
  });
  const notes = callNotesData?.call?.notes ?? [];

  useEffect(() => {
    if (data?.call?.transcript.length) {
      stopPolling();
    }
  }, [data]);

  return {
    data: {
      transcript: data?.call?.transcript ?? [],
      notesToHighlight: notes,
      loading,
      error,
    },
    stopPolling,
  };
};

export default useCallTranscript;
