import { ApolloCache } from "@apollo/client";
import { Text } from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import invariant from "invariant";
import React, { useState } from "react";

import { errorToast, Link, successToast, useToast } from "../../../components";
import {
  ExternalPlaylistShare,
  Playlist,
  PlaylistShare,
  PlaylistSidebarItemFragment,
  PlaylistSidebarItemFragmentDoc,
  useCallShareLazyQuery,
  useCreateAndSharePlaylistExternallyMutation,
  useCreateAndSharePlaylistMutation,
  useCreateExternalPlaylistShareMutation,
  useCreatePlaylistShareMutation,
  usePlaylistSharesLazyQuery,
  useRemoveExternalPlaylistShareMutation,
  useRemovePlaylistShareMutation,
  useRenewExternalPlaylistShareMutation,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Share, ShareModalProps } from "../ShareModal/types";

export interface UseSharePlaylistModalParams {
  playlistId?: string;
  callId: string;
  clipIds?: string[];
  onClose(): void;
  onShare?(): void;
}

export type UseSharePlaylistModal = Omit<ShareModalProps, "onClose"> & {
  getShareData(): Promise<any>;
  hasSelection: boolean;
  loading: boolean;
};

/**
 * This hook takes either a playlistId for an existing playlist,
 * or a callId + clipIds used to create + share a playlist.
 *
 * Like useCallShareModal() amd useClipShareModal(), this hook sets up
 * all of the queries and mutations required to run the share modal for a
 * playlist and returns them as props that can be passed to the share modal
 */
export function useSharePlaylistModal({
  playlistId,
  callId,
  clipIds,
  onClose,
  onShare,
}: UseSharePlaylistModalParams): UseSharePlaylistModal {
  invariant(
    playlistId || (callId && !!clipIds),
    "SharePlaylistModal requires either playlistId or (callId and non-empty clipIds)"
  );

  const currentUser = useCurrentUser();
  const [hasSelection, setHasSelection] = useState(false);
  const toast = useWrappedToast();

  const [getCallShareData, callShareQuery] = useCallShareLazyQuery({
    variables: { id: callId, forPlaylist: true },
    onError: (err) => {
      Sentry.captureException(err);
      onClose();
      toast.error(`There was a problem sharing this playlist`);
    },
  });
  const [getPlaylistShareData, playlistShareQuery] = usePlaylistSharesLazyQuery(
    {
      onError(err) {
        Sentry.captureException(err);
        onClose();
        toast.error(`There was a problem sharing this playlist`);
      },
    }
  );
  const getShareData = (): Promise<any> => {
    const fetches: Promise<any>[] = [getCallShareData()];
    if (playlistId) {
      fetches.push(getPlaylistShareData({ variables: { playlistId } }));
    }
    return Promise.all(fetches);
  };

  const {
    canShare,
    canShareExternal,
    name: callName,
    candidate,
    shareableUsers,
  } = callShareQuery.data?.call ?? { canShare: false };

  const playlistShareData = playlistShareQuery.data?.playlist;
  const internalShares: Share[] = playlistShareData?.shares || [];
  const externalShares: Share[] = playlistShareData?.externalShares || [];
  const sharedWithUnique = externalShares
    .concat(internalShares)
    .filter(
      (share, index, shares) =>
        shares.findIndex((s) => s.sharedTo?.id === share.sharedTo?.id) === index
    );

  const removeShareFromCache = (opts: {
    cache: ApolloCache<any>;
    shareId: string;
    external?: boolean;
  }): void => {
    const { cache, shareId, external } = opts;
    const field: keyof Playlist = external ? "externalShares" : "shares";
    const cacheKey = { __typename: "Playlist", id: playlistId };

    cache.modify({
      id: cache.identify(cacheKey),
      fields: {
        [field]: (
          existing: (ExternalPlaylistShare | PlaylistShare)[],
          { readField }
        ) => existing.filter((share) => shareId !== readField("id", share)),
      },
    });
  };

  const addPlaylistToSidebarCache = (opts: {
    cache: ApolloCache<any>;
    playlist: PlaylistSidebarItemFragment;
  }): void => {
    const { cache, playlist } = opts;
    cache.modify({
      id: cache.identify({ __typename: "User", id: currentUser.id }),
      fields: {
        playlists: ({ results, ...existing }) => ({
          ...existing,
          results: [
            cache.writeFragment({
              data: playlist,
              fragment: PlaylistSidebarItemFragmentDoc,
            }),
            ...results,
          ],
        }),
      },
    });
  };

  const [createAndSharePlaylist, createAndShareMutation] =
    useCreateAndSharePlaylistMutation({
      update(cache, { data }) {
        const playlist = data?.createAndSharePlaylist?.playlist;
        if (playlist) {
          addPlaylistToSidebarCache({ cache, playlist });
        }
      },
    });

  const [sharePlaylist, shareMutation] = useCreatePlaylistShareMutation();

  const [removeShare, { loading: removeShareLoading }] =
    useRemovePlaylistShareMutation({
      update(cache, { data }) {
        const shareId = data?.removePlaylistShare?.playlistShare.id;
        if (shareId) {
          removeShareFromCache({ cache, shareId });
        }
      },
      onError(err) {
        Sentry.captureException(err);
        toast.error("There was a problem removing this share");
      },
      onCompleted(data) {
        if (data.removePlaylistShare) {
          toast.success("Removed share");
        }
      },
    });

  const [createAndSharePlaylistExternally, createAndShareExternallyMutation] =
    useCreateAndSharePlaylistExternallyMutation({
      update(cache, { data }) {
        const playlist = data?.createAndSharePlaylistExternally?.playlist;
        if (playlist) {
          addPlaylistToSidebarCache({ cache, playlist });
        }
      },
    });

  const [sharePlaylistExternally, shareExternallyMutation] =
    useCreateExternalPlaylistShareMutation();

  const [renewExternalShare, { loading: renewExternalShareLoading }] =
    useRenewExternalPlaylistShareMutation({
      onError(err) {
        Sentry.captureException(err);
        toast.error("There was a problem renewing this share");
      },
      onCompleted(data) {
        if (data.renewExternalPlaylistShare) {
          toast.success("Successfully renewed external share");
        }
      },
    });

  const [removeExternalShare, { loading: removeExternalShareLoading }] =
    useRemoveExternalPlaylistShareMutation({
      update(cache, { data }) {
        const shareId = data?.removeExternalPlaylistShare?.playlistShare.id;
        if (shareId) {
          removeShareFromCache({ cache, shareId, external: true });
        }
      },
      onError(err) {
        Sentry.captureException(err);
        toast.error("There was a problem removing this external share");
      },
      onCompleted(data) {
        if (data.removeExternalPlaylistShare) {
          toast.success("Removed external share");
        }
      },
    });

  const getDefaultPlaylistTitle = (): string => {
    if (callName) {
      return `${callName} Playlist`;
    }
    if (candidate?.fullName) {
      return `Interview with ${candidate?.fullName} Playlist`;
    }
    if (clipIds) {
      return `${clipIds.length} Clip Playlist`;
    }
    return "Playlist";
  };

  return {
    includeTitleInput: !playlistId,
    defaultTitle: getDefaultPlaylistTitle(),
    modalTitle: "Share Playlist",
    optionsLoading: callShareQuery.loading,
    shareableUsers,
    onSelection: (selection) => setHasSelection(!!selection.length),
    canShare,
    onShare({ internalIds, externalEmails, message: shareMessage, title }) {
      let internalMutation: Promise<{ playlistId: string }> | null = null;
      let externalMutation: Promise<{ playlistId: string }> | null = null;

      if (playlistId) {
        if (internalIds.length > 0) {
          internalMutation = sharePlaylist({
            variables: {
              playlistId,
              shareMessage,
              shareToUserIds: internalIds,
            },
          }).then(() => ({ playlistId }));
        }
        if (externalEmails.length > 0) {
          externalMutation = sharePlaylistExternally({
            variables: {
              playlistId,
              shareMessage,
              shareToEmails: externalEmails,
            },
          }).then(() => ({ playlistId }));
        }
      } else if (clipIds) {
        if (internalIds.length > 0 && externalEmails.length > 0) {
          internalMutation = createAndSharePlaylist({
            variables: {
              clipIds,
              shareMessage,
              shareToUserIds: internalIds,
              title,
              description: "",
            },
          }).then(({ data }) => ({
            playlistId: data?.createAndSharePlaylist?.playlist.id as string,
          }));

          externalMutation = internalMutation.then((response) =>
            sharePlaylistExternally({
              variables: {
                playlistId: response.playlistId,
                shareMessage,
                shareToEmails: externalEmails,
              },
            }).then(() => response)
          );
        } else if (internalIds.length > 0) {
          internalMutation = createAndSharePlaylist({
            variables: {
              clipIds,
              shareMessage,
              shareToUserIds: internalIds,
              title,
              description: "",
            },
          }).then(({ data }) => ({
            playlistId: data?.createAndSharePlaylist?.playlist.id as string,
          }));
        } else if (externalEmails.length > 0) {
          externalMutation = createAndSharePlaylistExternally({
            variables: {
              clipIds,
              shareMessage,
              shareToEmails: externalEmails,
              title,
              description: "",
            },
          }).then(({ data }) => ({
            playlistId: data?.createAndSharePlaylistExternally?.playlist
              .id as string,
          }));
        }
      }

      Promise.all([internalMutation, externalMutation])
        .then(([internalShare, externalShare]) => {
          if (internalShare || externalShare) {
            toast.success(
              "Playlist shared",
              internalShare?.playlistId ?? externalShare?.playlistId
            );
            onClose();
            onShare?.();
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          toast.error("There was a problem sharing this playlist");
        });
    },
    removeShare(share) {
      if (!removeShareLoading) {
        removeShare({ variables: { shareId: share.id } });
      }
    },

    canShareExternal,
    renewExternalShare({ id }) {
      if (!renewExternalShareLoading) {
        renewExternalShare({ variables: { id } });
      }
    },
    async removeExternalShare(share) {
      if (!removeExternalShareLoading) {
        await removeExternalShare({ variables: { shareId: share.id } });
      }
    },

    shareLoading:
      shareMutation.loading ||
      shareExternallyMutation.loading ||
      createAndShareMutation.loading ||
      createAndShareExternallyMutation.loading,

    getShareData,
    hasSelection,
    loading: callShareQuery.loading || playlistShareQuery.loading,

    sharedWith: sharedWithUnique,
    externalShareDuration:
      currentUser.organization.externalShareDefaultDurationDays,
  };
}

function useWrappedToast(): {
  error(msg?: string): void;
  success(text: string, playlistId?: string): void;
} {
  const toast = useToast();

  return {
    error(msg) {
      errorToast(toast, msg);
    },

    success(text, playlistId) {
      successToast(
        toast,
        <Text>
          {`${text} `}
          {playlistId && (
            <Link
              fontWeight="semibold"
              href={`/playlist/${playlistId}`}
              target="_blank"
            >
              View playlist
            </Link>
          )}
        </Text>,
        { duration: 7_000, isClosable: true }
      );
    },
  };
}
