import {
  HStack,
  Icon,
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import React from "react";

import { RouterLink } from "../../../../components";
import {
  SortIconAscending,
  SortIconDescending,
} from "../../../../components/Icons/SortIcons";
import {
  CandidateRedactionSortField,
  RedactCandidateItem,
  SortDirection,
} from "../../../graphql";
import { TdWhite, ThGray } from "../../analytics/AnalyticsReportTable";

const TABLE_CELL_PROPS: TableCellProps = {
  px: 4,
  minW: "100px",
  textAlign: "left",
};

interface DataRetentionCandidatesTableProps {
  tableData: RedactCandidateItem[];
  loading: boolean;
  activeSort?: CandidateRedactionSortField;
  sortDirection?: SortDirection;
  onSortChange?: (column: CandidateRedactionSortField) => void;
}

const DataRetentionCandidatesTable: React.FC<
  DataRetentionCandidatesTableProps
> = ({ tableData, loading, activeSort, sortDirection, onSortChange }) => {
  const renderSortIcon = (
    columnName: CandidateRedactionSortField
  ): React.ReactNode => {
    if (activeSort !== columnName) {
      return null;
    }
    return (
      <Icon
        as={
          sortDirection === SortDirection.Asc
            ? SortIconAscending
            : SortIconDescending
        }
        ml={2}
        boxSize={4}
        color="gray.600"
      />
    );
  };

  const handleSort = (columnName: CandidateRedactionSortField): void => {
    onSortChange?.(columnName);
  };

  return (
    <TableContainer>
      <Table variant="simple" whiteSpace="normal">
        <Thead>
          <Tr>
            <ThWrapper
              paddingLeft="4"
              onClick={() => handleSort(CandidateRedactionSortField.Candidate)}
              cursor="pointer"
            >
              <HStack spacing={1}>
                <Text>Candidate</Text>
                {renderSortIcon(CandidateRedactionSortField.Candidate)}
              </HStack>
            </ThWrapper>
            <ThWrapper
              onClick={() => handleSort(CandidateRedactionSortField.HiredDate)}
              cursor="pointer"
            >
              <HStack spacing={1}>
                <Text>Hired Date</Text>
                {renderSortIcon(CandidateRedactionSortField.HiredDate)}
              </HStack>
            </ThWrapper>
            <ThWrapper
              onClick={() =>
                handleSort(CandidateRedactionSortField.RejectedDate)
              }
              cursor="pointer"
            >
              <HStack spacing={1}>
                <Text>Rejected Date</Text>
                {renderSortIcon(CandidateRedactionSortField.RejectedDate)}
              </HStack>
            </ThWrapper>
            <ThWrapper
              onClick={() =>
                handleSort(CandidateRedactionSortField.LastInterviewDate)
              }
              cursor="pointer"
            >
              <HStack spacing={1}>
                <Text>Last Interview Date</Text>
                {renderSortIcon(CandidateRedactionSortField.LastInterviewDate)}
              </HStack>
            </ThWrapper>
            <ThWrapper
              paddingLeft="4"
              onClick={() => handleSort(CandidateRedactionSortField.Interviews)}
              cursor="pointer"
            >
              <HStack spacing={1}>
                <Text>Interviews</Text>
                {renderSortIcon(CandidateRedactionSortField.Interviews)}
              </HStack>
            </ThWrapper>
          </Tr>
        </Thead>
        <Tbody>
          {loading && (
            <Tr>
              <Td colSpan={5} textAlign="center">
                <Text color="gray.600" fontSize="sm">
                  Loading...
                </Text>
              </Td>
            </Tr>
          )}
          {!loading && !tableData.length && (
            <Tr>
              <Td colSpan={5} textAlign="center">
                <Text color="gray.600" fontSize="sm">
                  No candidates found. Please try a different filter.
                </Text>
              </Td>
            </Tr>
          )}
          {!loading &&
            tableData.map((row) => (
              <Tr key={row.id}>
                <TdWrapper>
                  <RouterLink to={`/candidate/${row.id || ""}`}>
                    {row.fullName}
                  </RouterLink>
                </TdWrapper>
                <TdWrapper paddingLeft="4">
                  <Text color="gray.800" fontWeight="500">
                    {row.hiredAt &&
                      format(parseISO(row.hiredAt), "MMM dd, yyyy")}
                  </Text>
                </TdWrapper>
                <TdWrapper>
                  <Text color="gray.800" fontWeight="500">
                    {row.rejectedAt &&
                      format(parseISO(row.rejectedAt), "MMM dd, yyyy")}
                  </Text>
                </TdWrapper>
                <TdWrapper>
                  <Text color="gray.800" fontWeight="500">
                    {row.lastInterviewAt &&
                      format(parseISO(row.lastInterviewAt), "MMM dd, yyyy")}
                  </Text>
                </TdWrapper>
                <TdWrapper>
                  <Text>{row.numInterviews}</Text>
                </TdWrapper>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const ThWrapper: React.FC<
  {
    children: React.ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => (
  <ThGray {...TABLE_CELL_PROPS} width="20%" {...props}>
    {children}
  </ThGray>
);

const TdWrapper: React.FC<
  {
    children: React.ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => (
  <TdWhite {...TABLE_CELL_PROPS} {...props} borderColor="inherit">
    {children}
  </TdWhite>
);

export default DataRetentionCandidatesTable;
