import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { MdList } from "react-icons/md";

import { Button, ConfirmModal, useToast } from "../../../../components";
import { EmptyStateWithIcon } from "../../../../components/EmptyStates";
import Pagination from "../../../components/Pagination";
import {
  Ats,
  CandidateRedactionSortField,
  CandidateRedactionStatus,
  SortDirection,
  useOrganizationRedactionCandidatesQuery,
  useRedactScheduledCandidatesMutation,
} from "../../../graphql";
import LabeledChartSelect from "../../analytics/LabeledChartSelect";
import { SingleSelect } from "../../analytics/types";
import DataRetentionCandidatesTable from "./DataRetentionCandidatesTable";

interface DataRetentionCandidatesProps {
  organization: {
    ats?: Ats | null;
    enableRetentionSinceCandidateHired: boolean;
    retentionDaysSinceCandidateHired: number;
    enableRetentionSinceCandidateRejected: boolean;
    retentionDaysSinceCandidateRejected: number;
    enableRetentionSinceLastInterview: boolean;
    retentionDaysSinceLastInterview: number;
    enableRetentionSinceInterview: boolean;
    retentionDaysSinceInterview: number;
    enableRedactionForGreenhouse: boolean;
    enableRedactionForLever: boolean;
    retentionNotificationDays?: (string | null)[] | null;
    organizationRetentionUser: {
      userId: string;
    }[];
    retentionEmailSendTime: string;
    retentionEmailSendTimezone: string;
  };
}

const DataRetentionCandidatesPageBeta: React.FC<
  DataRetentionCandidatesProps
> = ({ organization }) => {
  const toast = useToast();
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [activeSort, setActiveSort] = useState<CandidateRedactionSortField>(
    CandidateRedactionSortField.Candidate
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Desc
  );
  const [reasonFilter, setReasonFilter] = useState<CandidateRedactionStatus>(
    CandidateRedactionStatus.HiredOrRejected
  );

  const { data, loading, error } = useOrganizationRedactionCandidatesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      page: page - 1,
      candidateStatus: reasonFilter,
      sortBy: activeSort,
      sortDirection,
    },
  });
  const candidates = data?.candidatesToRedact?.candidates || [];
  const totalCount = data?.candidatesToRedact?.totalCandidateCount;
  const numPages = data?.candidatesToRedact?.numPages;

  useEffect(() => {
    setPage(1);
  }, [reasonFilter]);

  const [redactScheduledCandidates, { loading: redactScheduledLoading }] =
    useRedactScheduledCandidatesMutation({
      onCompleted: (data) => {
        toast({
          title: "Success",
          description:
            "Your request is being processed. Please reload the page in a few minutes.",
          status: "success",
        });
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
    });

  const handleClick = (modalText: string): void => {
    setIsOpen(true);
    setModalText(modalText);
  };

  useEffect(() => {
    if (error) {
      toast({
        title: "Error loading candidates",
        description: error.message,
        status: "error",
      });
    }
  }, [error]);

  const candidateReasonSelect = useMemo<SingleSelect<CandidateRedactionStatus>>(
    () => ({
      options: [
        {
          label: "Hired or Rejected",
          value: CandidateRedactionStatus.HiredOrRejected,
        },
        { label: "Hired", value: CandidateRedactionStatus.Hired },
        { label: "Rejected", value: CandidateRedactionStatus.Rejected },
      ],
      value: reasonFilter,
      setValue: setReasonFilter,
    }),
    [reasonFilter, setReasonFilter]
  );

  const buttonLabel = useMemo(() => {
    const candidateSingleOrPlural =
      totalCount === 1 ? "candidate" : "candidates";
    if (totalCount) {
      if (reasonFilter === CandidateRedactionStatus.HiredOrRejected) {
        return `Delete ${totalCount} ${candidateSingleOrPlural}`;
      }
      if (reasonFilter === CandidateRedactionStatus.Hired) {
        return `Delete ${totalCount} hired ${candidateSingleOrPlural}`;
      }
      if (reasonFilter === CandidateRedactionStatus.Rejected) {
        return `Delete ${totalCount} rejected ${candidateSingleOrPlural}`;
      }
    }
    return `Delete ${totalCount || 0} ${candidateSingleOrPlural}`;
  }, [totalCount, reasonFilter]);

  const handleSort = (column: CandidateRedactionSortField): void => {
    if (activeSort === column) {
      setSortDirection(
        sortDirection === SortDirection.Asc
          ? SortDirection.Desc
          : SortDirection.Asc
      );
    } else {
      setActiveSort(column);
      setSortDirection(SortDirection.Desc);
    }
  };

  if (error) {
    return null;
  }

  if (
    organization.enableRetentionSinceCandidateHired === false &&
    organization.enableRetentionSinceCandidateRejected === false &&
    organization.enableRetentionSinceLastInterview === false
  ) {
    return (
      <EmptyStateWithIcon
        heading="Candidate section disabled"
        icon={MdList}
        Text={() => (
          <Box>
            You have not enabled candidate deletion settings. The list of
            candidates to be deleted will be shown here after enabling and
            setting candidate data retention timelines.{" "}
          </Box>
        )}
        padded={false}
      />
    );
  }

  return (
    <Box my={6}>
      <Flex
        alignItems="flex-end"
        mb={5}
        justifyContent="space-between"
        w="100%"
      >
        <LabeledChartSelect
          flexDir="column"
          alignItems="flex-start"
          label="Include candidates that were: "
          singleSelect={candidateReasonSelect}
          customSelectStyles={{
            container: (provided: any) => ({
              ...provided,
              width: "250px",
            }),
          }}
        />
        <Flex alignItems="center">
          <ConfirmModal
            modalBodyText={modalText}
            modalTitleText="Confirm Deletion"
            modalSubmitText="Delete"
            isOpen={isOpen}
            onCancel={() => {
              setIsOpen(false);
            }}
            onConfirm={() => {
              redactScheduledCandidates({
                variables: { candidateStatus: reasonFilter },
              });
              setIsOpen(false);
            }}
          />
          <Button
            size="sm"
            type="submit"
            variant="danger"
            isLoading={loading || redactScheduledLoading}
            isDisabled={!totalCount}
            onClick={() =>
              handleClick(
                ` Are you sure you want to ${buttonLabel.toLowerCase()}?`
              )
            }
          >
            {buttonLabel}
          </Button>
        </Flex>
      </Flex>
      <>
        <DataRetentionCandidatesTable
          tableData={candidates}
          loading={loading}
          activeSort={activeSort}
          sortDirection={sortDirection}
          onSortChange={handleSort}
        />
        <Pagination
          currentPageNumber={page}
          totalPageCount={numPages || 1}
          onChangePage={(next) => setPage(next)}
        />
      </>
    </Box>
  );
};

export default DataRetentionCandidatesPageBeta;
