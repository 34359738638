import { Box, BoxProps, Flex, Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { MdCheckCircle, MdError, MdWarning } from "react-icons/md";

interface StatusWithTextProps {
  passed: boolean;
  label: string;
  warn?: boolean;
  tooltipLabel?: string;
}

const StatusWithText: React.FC<StatusWithTextProps & BoxProps> = ({
  passed,
  label,
  warn = false,
  tooltipLabel,
  ...rest
}) => (
  <Tooltip label={tooltipLabel}>
    <Flex mb={4} alignItems="center" {...rest}>
      {passed ? (
        <Icon as={MdCheckCircle} color="green.600" />
      ) : warn ? (
        <Icon as={MdWarning} color="yellow.400" />
      ) : (
        <Icon as={MdError} color="red.600" />
      )}
      <Box ml={2}>{label}</Box>
    </Flex>
  </Tooltip>
);

export default StatusWithText;
