import { MetricName } from "../../graphql";
import { getStructuredFormatFromValue } from "../../pages/analytics/utils";
import {
  SearchHit,
  SearchHitAnalytics,
  SearchHitAnalyticsConfig,
} from "./types";

// return null for generic no data response
const NOT_AVAILABLE_TEXT = "Not Available";
const NO_FEEDBACK_TEXT = "No Feedback";
const searchHitMetricConfig: SearchHitAnalyticsConfig = {
  [MetricName.OnTimeInterviews]: (hit: SearchHit) => {
    if (hit.isOnTimeStart === null) return null;
    return {
      text: hit.isOnTimeStart === true ? "On Time Start" : "Late Start",
      valence: hit.isOnTimeStart === true ? "positive" : "negative",
    };
  },
  [MetricName.CandidateTalkRatio]: (hit: SearchHit) => {
    if (typeof hit.candidateTalkTimeRatio !== "number") return null;
    return {
      text: `Candidate Talk Ratio: ${Math.floor(
        hit.candidateTalkTimeRatio * 100
      )}%`,
      valence: "neutral",
    };
  },
  [MetricName.Interactivity]: (hit: SearchHit) => {
    if (typeof hit.adjustedCallInteractivity !== "number") return null;
    return {
      text: `Interactivity: ${hit.adjustedCallInteractivity}`,
      valence: "neutral",
    };
  },
  [MetricName.QuestionsAsked]: (hit: SearchHit) => {
    const interviewers = hit.analyticsInterviewers;
    if (!interviewers?.length) return null;
    const validInterviewers = interviewers.filter(
      (interviewer) => interviewer.countQuestions !== null
    );
    const valueText =
      validInterviewers.length > 0
        ? `${validInterviewers.reduce(
            (acc, interviewer) => acc + (interviewer.countQuestions as number),
            0
          )}`
        : NOT_AVAILABLE_TEXT;
    return {
      text: `Questions Asked: ${valueText}`,
      tooltipLines: interviewers.map(
        (interviewer) =>
          `${interviewer.fullName}: ${
            interviewer.countQuestions !== null
              ? `${interviewer.countQuestions} Questions`
              : NOT_AVAILABLE_TEXT
          }`
      ),
      valence: "neutral",
    };
  },
  [MetricName.LongestMonologue]: (hit: SearchHit) => {
    if (typeof hit.interviewerLongestMonologueSeconds !== "number") return null;
    const interviewers = hit.analyticsInterviewers;
    if (!interviewers?.length) return null;

    return {
      text: `Longest Monologue: ${hit.interviewerLongestMonologueSeconds} Seconds`,
      tooltipLines: interviewers.map(
        (interviewer) =>
          `${interviewer.fullName}: ${
            interviewer.longestMonologue !== null
              ? `${interviewer.longestMonologue} Seconds`
              : NOT_AVAILABLE_TEXT
          }`
      ),
      valence: "neutral",
    };
  },
  [MetricName.SpeakingRate]: (hit: SearchHit) => {
    const interviewers = hit.analyticsInterviewers;
    if (!interviewers?.length) return null;

    const validInterviewers = interviewers.filter(
      (interviewer) => typeof interviewer.wordsPerMinute === "number"
    );
    const valueText =
      validInterviewers.length > 0
        ? `${Math.floor(
            validInterviewers.reduce(
              (acc, interviewer) =>
                acc + (interviewer.wordsPerMinute as number),
              0
            ) / validInterviewers.length
          )} words per minute`
        : NOT_AVAILABLE_TEXT;
    return {
      text: `Avg. Speaking Rate: ${valueText}`,
      tooltipLines: interviewers.map(
        (interviewer) =>
          `${interviewer.fullName}: ${
            interviewer.wordsPerMinute !== null
              ? `${Math.floor(interviewer.wordsPerMinute)} wpm`
              : NOT_AVAILABLE_TEXT
          }`
      ),
      valence: "neutral",
    };
  },
  [MetricName.PassRate]: (hit: SearchHit) => {
    const interviewers = hit.analyticsInterviewers;
    if (!interviewers?.length) return null;

    const complete = interviewers.filter(
      (interviewer) => interviewer.isPositiveFeedback !== null
    );
    const numPositiveFeedback = complete.filter(
      (interviewer) => interviewer.isPositiveFeedback === true
    ).length;

    const numNegativeFeedback = complete.filter(
      (interviewer) => interviewer.isPositiveFeedback === false
    ).length;
    const valueText =
      complete.length > 0
        ? `${Math.floor(
            (numPositiveFeedback /
              (numPositiveFeedback + numNegativeFeedback)) *
              100
          )}%`
        : NO_FEEDBACK_TEXT;

    if (interviewers.length === 1 && complete.length === 0) {
      return {
        text: `Feedback Rating: N/A - No feedback submitted`,
        valence: "neutral",
      };
    }

    if (interviewers.length === 1 && complete.length === 1) {
      return {
        text: `Feedback Rating: ${
          interviewers[0].isPositiveFeedback ? "Positive" : "Negative"
        }`,
        tooltipLines: [
          `${interviewers[0].fullName}: ${
            interviewers[0].isPositiveFeedback ? "Positive" : "Negative"
          }`,
        ],
        valence: "neutral",
      };
    }

    return {
      text: `Avg positive feedback rate: ${
        interviewers.length > complete.length
          ? "N/A - Not all feedback submitted"
          : valueText
      }`,
      tooltipLines: interviewers.map(
        (interviewer) =>
          `${interviewer.fullName}: ${
            interviewer.isPositiveFeedback !== null
              ? interviewer.isPositiveFeedback
                ? "Positive"
                : "Negative"
              : NO_FEEDBACK_TEXT
          }`
      ),
      valence: "neutral",
    };
  },
  [MetricName.ScorecardCompletionRate]: (hit: SearchHit) => {
    const interviewers = hit.analyticsInterviewers;
    if (!interviewers?.length) return null;

    const numComplete = interviewers.filter(
      (interviewer) => interviewer.scorecardSubmissionTime !== null
    ).length;

    if (interviewers.length === 1 && numComplete === 0) {
      return {
        text: `Feedback Submission rate: 0% - No feedback submitted`,
        tooltipLines: interviewers.map(
          (interviewer) => `${interviewer.fullName}: No feedback submitted`
        ),
        valence: "neutral",
      };
    }

    if (interviewers.length === 1 && numComplete === 1) {
      return {
        text: `Feedback submission rate: 100% - Feedback submitted`,
        tooltipLines: [`${interviewers[0].fullName}: Feedback submitted`],
        valence: "neutral",
      };
    }

    return {
      text: `Avg Feedback Submission rate: ${Math.floor(
        (numComplete / interviewers.length) * 100
      )}%`,
      tooltipLines: interviewers.map(
        (interviewer) =>
          `${interviewer.fullName}: Feedback ${
            interviewer.scorecardSubmissionTime !== null
              ? "Submitted"
              : "Not Submitted"
          }`
      ),
      valence: "neutral",
    };
  },
  [MetricName.ScorecardCompletionTime]: (hit: SearchHit) => {
    const interviewers = hit.analyticsInterviewers;
    if (!interviewers?.length) return null;

    const complete = interviewers.filter(
      (interviewer) => interviewer.scorecardSubmissionTime !== null
    );
    const avgScorecardCompletionTime = Math.floor(
      complete.reduce(
        (acc, interviewer) => acc + (interviewer.scorecardSubmissionTime || 0),
        0
      ) / complete.length
    );
    const valueText =
      complete.length > 0
        ? `${getStructuredFormatFromValue(
            avgScorecardCompletionTime,
            "days-hours"
          )
            .map((val) => `${val.value}${val.unitSymbol}`)
            .join(" ")}`
        : NO_FEEDBACK_TEXT;

    if (interviewers.length === 1 && complete.length === 0) {
      return {
        text: `Feedback Submission time: N/A - No feedback submitted`,
        tooltipLines: interviewers.map(
          (interviewer) => `${interviewer.fullName}: No feedback submitted`
        ),
        valence: "neutral",
      };
    }

    if (interviewers.length === 1 && complete.length === 1) {
      return {
        text: `Feedback Submission time: ${getStructuredFormatFromValue(
          interviewers[0].scorecardSubmissionTime,
          "days-hours"
        )
          .map((val) => `${val.value}${val.unitSymbol}`)
          .join(" ")}`,
        tooltipLines: [
          `${interviewers[0].fullName}: ${getStructuredFormatFromValue(
            interviewers[0].scorecardSubmissionTime,
            "days-hours"
          )
            .map((val) => `${val.value}${val.unitSymbol}`)
            .join(" ")}`,
        ],
        valence: "neutral",
      };
    }

    return {
      text: `Avg. Feedback Submission time: ${
        interviewers.length > complete.length
          ? "N/A - Not all feedback submitted"
          : valueText
      }`,
      tooltipLines: interviewers.map(
        (interviewer) =>
          `${interviewer.fullName}: ${
            interviewer.scorecardSubmissionTime
              ? `${getStructuredFormatFromValue(
                  interviewer.scorecardSubmissionTime,
                  "days-hours"
                )
                  .map((val) => `${val.value}${val.unitSymbol}`)
                  .join(" ")}`
              : NO_FEEDBACK_TEXT
          }`
      ),
      valence: "neutral",
    };
  },
};

export const getSearchHitAnalytics: (
  hit: SearchHit,
  metricParam: string
) => SearchHitAnalytics | null = (hit, metric) => {
  const analyticsDataFunc = searchHitMetricConfig[metric as MetricName];
  // check if analyticsDataFunc is defined for metric
  if (analyticsDataFunc) {
    const data = analyticsDataFunc(hit);
    if (data) {
      return data;
    }
    return {
      text: "No Data Available",
      tooltipLines: [
        "Insights data cannot be computed for certain calls. Please reach out to BrightHire support for more information.",
      ],
    };
  }
  // if null returned no analytics will be returned for this metric param
  return null;
};
