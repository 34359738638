import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";

import { Alert, Button } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { isValidPhoneNumber } from "../../../../utils/phone";
import { phoneNumber as phoneNumberRegex } from "../../../../utils/regex";
import {
  CandidateFragment,
  CandidatePhoneType,
  useUpdateCandidateMutation,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";

export type EditCandidateFragment = Pick<
  CandidateFragment,
  "id" | "firstName" | "lastName" | "defaultEmail" | "defaultPhoneNumberObj"
>;

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneType: CandidatePhoneType;
}

export interface EditCandidateModalProps {
  candidate: EditCandidateFragment;
  isOpen: boolean;
  onClose: () => void;
}

const EditCandidateModal: React.FC<EditCandidateModalProps> = ({
  candidate,
  isOpen,
  onClose,
}) => {
  const defaultValues = {
    firstName: candidate.firstName,
    lastName: candidate.lastName,
    email: candidate.defaultEmail ?? "",
    phoneNumber: candidate.defaultPhoneNumberObj?.phoneNumber ?? "",
    phoneType:
      candidate.defaultPhoneNumberObj?.type ?? CandidatePhoneType.Mobile,
  };
  const sendGAEvent = useSendGAEvent();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
  });
  const [updateCandidate, { loading, error }] = useUpdateCandidateMutation({
    onCompleted: ({ updateCandidate }) => {
      if (updateCandidate?.candidate) {
        onClose();
        reset({
          firstName: updateCandidate.candidate.firstName,
          lastName: updateCandidate.candidate.lastName,
          email: updateCandidate.candidate.defaultEmail ?? undefined,
          phoneNumber:
            updateCandidate.candidate.defaultPhoneNumberObj?.phoneNumber ?? "",
          phoneType: updateCandidate.candidate.defaultPhoneNumberObj?.type,
        });
      }
    },
  });
  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = (formData: FormData): void => {
    sendGAEvent("update", "candidates");
    updateCandidate({
      variables: { id: candidate.id, ...formData },
    });
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Enter" && formRef.current) {
      formRef.current.dispatchEvent(new Event("submit"));
    }
  };

  const currentUser = useCurrentUser();
  const intlOutboundEnabled = currentUser.isInternationalCallingEnabled;
  const phoneNumPlaceholder = intlOutboundEnabled
    ? "+xx xxxxx-xxxxx"
    : "(xxx) xxx-xxxx";

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
      <ModalOverlay>
        <ModalContent borderRadius="24px">
          <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <ModalHeader color="gray.900" fontWeight="600" fontSize="2xl">
              Edit candidate details
            </ModalHeader>
            <ModalCloseButton
              data-testid="edit-candidate-modal-close"
              onClick={(e) => {
                e.preventDefault();
                reset(defaultValues);
                onClose();
              }}
            />
            <ModalBody>
              {error?.graphQLErrors?.map(({ message }, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Alert mb={6} key={i} status="error" description={message} />
              ))}
              <VStack spacing="4" align="stretch">
                <FormControl
                  id="firstName"
                  isInvalid={errors.firstName !== undefined}
                >
                  <FormLabel color="gray.600">First name</FormLabel>
                  <Input
                    {...register("firstName")}
                    autoComplete="given-name"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.firstName !== undefined && (
                    <FormErrorMessage>
                      {errors.firstName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  id="lastName"
                  isInvalid={errors.lastName !== undefined}
                >
                  <FormLabel color="gray.600">Last name</FormLabel>
                  <Input
                    {...register("lastName")}
                    autoComplete="family-name"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.lastName !== undefined && (
                    <FormErrorMessage>
                      {errors.lastName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl id="email" isInvalid={errors.email !== undefined}>
                  <FormLabel color="gray.600">Email</FormLabel>
                  <Input
                    {...register("email")}
                    type="email"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.email !== undefined && (
                    <FormErrorMessage>{errors.email.message}</FormErrorMessage>
                  )}
                </FormControl>
                <Flex columnGap="4">
                  <FormControl id="type" w="180px">
                    <FormLabel>Type</FormLabel>
                    <Select
                      data-testid="phone-type-select"
                      {...register("phoneType")}
                      textTransform="capitalize"
                    >
                      {Object.entries(CandidatePhoneType).map(
                        ([key, value]) => (
                          <option key={key} value={value}>
                            {value}
                          </option>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl
                    id="phoneNumber"
                    isInvalid={errors.phoneNumber !== undefined}
                  >
                    <FormLabel color="gray.600">Phone Number</FormLabel>
                    <Input
                      {...register("phoneNumber", {
                        validate: {
                          isValid: (value) => {
                            if (!value) {
                              return true;
                            }
                            if (intlOutboundEnabled) {
                              return (
                                isValidPhoneNumber(value) ||
                                "Invalid phone number"
                              );
                            }
                            return (
                              phoneNumberRegex.test(value) ||
                              "Invalid phone number"
                            );
                          },
                        },
                      })}
                      type="phoneNumber"
                      placeholder={phoneNumPlaceholder}
                      onKeyPress={handleKeyPress}
                    />
                    {errors.phoneNumber !== undefined && (
                      <FormErrorMessage>
                        {errors.phoneNumber.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
              </VStack>
            </ModalBody>
            <ModalFooter
              borderTop="1px"
              borderTopColor="gray.100"
              justifyContent="space-between"
            >
              <Button
                variant="link"
                fontWeight="500"
                onClick={(e) => {
                  e.preventDefault();
                  reset(defaultValues);
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" isLoading={loading}>
                Done
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default EditCandidateModal;
