import { useCandidateSummaryPositionPermissionsQuery } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import { useCandidateSummaryFullyEnabled } from "./useCandidateSummaryFullyEnabled";

export const useCanViewCandidateSummary = (
  positionId: string | null
): boolean => {
  const uiFlagEnabled = useFeatureFlag("candidate_summary:ui");
  const fullyFlagEnabled = useCandidateSummaryFullyEnabled();
  const flagEnabled = fullyFlagEnabled || uiFlagEnabled;
  const { data: permissions } = useCandidateSummaryPositionPermissionsQuery({
    variables: {
      positionId,
    },
  });
  const canViewSummaries = Boolean(
    permissions?.candidateSummaryPositionPermissions?.canViewSummaries
  );

  return flagEnabled && canViewSummaries;
};
