import {
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import React from "react";

import { HighlightText, RouterLink } from "../../../../components";
import { TopicOccurrences } from "../../../graphql";
import AnalyticsReportHero from "../AnalyticsReportHero";
import { TdWhite, ThGray } from "../AnalyticsReportTable";

const TABLE_CELL_PROPS: TableCellProps = {
  px: 4,
  minW: "100px",
  textAlign: "left",
};

type AnalyticsTopicTrendsTableProps = {
  tableData: TopicOccurrences[];
  topicName: string;
  topicKeywords: string[];
};

const AnalyticsTopicTrendsTable: React.FC<AnalyticsTopicTrendsTableProps> = ({
  tableData,
  topicName,
  topicKeywords,
}) => {
  return (
    <>
      <TableContainer>
        <Table variant="simple" whiteSpace="normal">
          <Thead>
            <Tr>
              <ThWrapper width="12.5%" paddingLeft="4">
                Timestamp
              </ThWrapper>
              <ThWrapper width="12.5%">Interview title</ThWrapper>
              <ThWrapper width="12.5%">Speaker</ThWrapper>
              <ThWrapper width="12.5%">Speaker type</ThWrapper>
              <ThWrapper width="40%">Detected topic</ThWrapper>
              <ThWrapper width="12.5%">Topic</ThWrapper>
            </Tr>
          </Thead>
          <Tbody>
            {!!tableData.length &&
              tableData.map((row) => (
                <Tr key={row.segmentId} _last={{ borderColor: "transparent" }}>
                  <TdWrapper paddingLeft="4">
                    <Text color="gray.800" fontWeight="500">
                      {row.callStartTime &&
                        format(parseISO(row.callStartTime), "MMM dd, yyyy")}
                    </Text>
                  </TdWrapper>
                  <TdWrapper>
                    <RouterLink
                      to={`/interview/${row.callId}?t=${row.segmentStartTime}`}
                      color="blue.600"
                      fontWeight="500"
                      fontSize="sm"
                      target="_blank"
                    >
                      {row.callName || "Interview"}
                    </RouterLink>
                  </TdWrapper>
                  <TdWrapper>
                    <Text>{row.speakerLabel || "Speaker"}</Text>
                  </TdWrapper>
                  <TdWrapper>
                    <Text>{row.speakerType}</Text>
                  </TdWrapper>
                  <TdWrapper>
                    <HighlightText
                      text={row.segmentText}
                      keywords={topicKeywords}
                      highlightedStyle={{ bg: "dataViz.caduceusGold.500" }}
                      matchWholeWords
                    />
                  </TdWrapper>
                  <TdWrapper>
                    <Text>{topicName}</Text>
                  </TdWrapper>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {!tableData.length && <AnalyticsReportHero />}
    </>
  );
};

const ThWrapper: React.FC<
  {
    children: React.ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => (
  <ThGray {...TABLE_CELL_PROPS} {...props}>
    {children}
  </ThGray>
);

const TdWrapper: React.FC<
  {
    children: React.ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => (
  <TdWhite {...TABLE_CELL_PROPS} {...props} borderColor="inherit">
    {children}
  </TdWhite>
);

export default AnalyticsTopicTrendsTable;
