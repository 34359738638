import {
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import invariant from "invariant";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";

import { Button, useToast } from "../../../components";
import { isIntlPhoneNumber, isValidPhoneNumber } from "../../../utils/phone";
import { phoneExtension as phoneExtensionRegex } from "../../../utils/regex";
import { useSignInByPhoneMutation } from "../../graphql";

interface SignInByPhoneFormProps {
  phoneNumber: string | undefined;
  phoneNumberExtension: string | undefined;
  onVerificationCodeSent: (verificationInfo: {
    phoneNumber: string;
    phoneNumberExtension: string;
    channel: "SMS" | "CALL";
  }) => void;
}

const SignInByPhoneForm: React.FC<SignInByPhoneFormProps> = ({
  phoneNumber,
  phoneNumberExtension,
  onVerificationCodeSent,
}) => {
  const toast = useToast();
  const channel = useRef<"SMS" | "CALL" | null>(null);
  const verificationInfo = useRef<{
    phoneNumber: string;
    phoneNumberExtension: string;
    channel: "SMS" | "CALL";
  } | null>(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{
    phoneNumber: string;
    phoneNumberExtension: string;
  }>({
    defaultValues: {
      phoneNumber: phoneNumber ?? "",
      phoneNumberExtension: phoneNumberExtension ?? "",
    },
  });

  const hasExtension = !!watch("phoneNumberExtension");
  const currentPhoneNumber = watch("phoneNumber");
  const isInternational = isIntlPhoneNumber(currentPhoneNumber || "");

  const [signIn, { loading }] = useSignInByPhoneMutation({
    onCompleted: (data) => {
      if (!data?.signInByPhone?.verificationSent) {
        return;
      }
      invariant(verificationInfo.current, "Missing verificationInfo");
      onVerificationCodeSent(verificationInfo.current);
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
        duration: 30000,
      });
    },
  });

  const onSubmit = handleSubmit(
    ({ phoneNumber, phoneNumberExtension }): void => {
      invariant(channel.current, "Missing channel");
      verificationInfo.current = {
        phoneNumber,
        phoneNumberExtension,
        channel: channel.current,
      };
      signIn({
        variables: {
          phoneNumber,
          phoneNumberExtension,
          channel: channel.current,
        },
      });
    }
  );

  return (
    <form onSubmit={onSubmit} autoComplete="on">
      <Flex>
        <FormControl
          id="phoneNumber"
          width="180px"
          isRequired
          isInvalid={errors.phoneNumber !== undefined}
        >
          <FormLabel>Phone number</FormLabel>
          <Input
            type="tel"
            {...register("phoneNumber", {
              validate: (value) => {
                return (
                  isValidPhoneNumber(value || "") || "Invalid phone number"
                );
              },
              required: "Phone number is required",
            })}
            data-testid="input-phone-number"
            aria-describedby="phone-helper-text"
            placeholder="(xxx) xxx-xxxx"
          />
          {errors.phoneNumber !== undefined && (
            <FormErrorMessage>{errors.phoneNumber.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          id="phoneNumberExtension"
          ml={4}
          width="100px"
          isInvalid={errors.phoneNumberExtension !== undefined}
        >
          <FormLabel>Extension</FormLabel>
          <Input
            {...register("phoneNumberExtension", {
              pattern: {
                value: phoneExtensionRegex,
                message: "Invalid extension",
              },
            })}
          />
          {errors.phoneNumberExtension !== undefined && (
            <FormErrorMessage>
              {errors.phoneNumberExtension.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </Flex>
      <Flex>
        <ButtonGroup spacing={4} mx="auto">
          <Tooltip
            label="Text messaging is only available for US phone numbers."
            isDisabled={!isInternational}
            hasArrow
          >
            <Button
              mt={6}
              mx="auto"
              isLoading={loading && channel.current === "SMS"}
              isDisabled={
                hasExtension ||
                isInternational ||
                (loading && channel.current !== "SMS")
              }
              onClick={() => {
                channel.current = "SMS";
                onSubmit();
              }}
            >
              Text me
            </Button>
          </Tooltip>
          <Button
            mt={6}
            mx="auto"
            isLoading={loading && channel.current === "CALL"}
            isDisabled={loading && channel.current !== "CALL"}
            onClick={() => {
              channel.current = "CALL";
              onSubmit();
            }}
          >
            Call me
          </Button>
        </ButtonGroup>
      </Flex>
    </form>
  );
};

export default SignInByPhoneForm;
