import useFeatureFlag from "../../../../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../../../../hooks/useCurrentUser";
import { useAsk2Enabled } from "./useAsk2Enabled";

export const useAskEnabled = (): boolean => {
  const currentUser = useCurrentUser();
  const orgSettingEnabled = Boolean(currentUser?.organization?.askEnabled);

  // Ask v1 is deprecated by Ask v2
  const ask2Enabled = useAsk2Enabled();

  const userEnabled = useFeatureFlag("ai-tabs:ask");
  const orgEnabled = useFeatureFlag("ai-tabs:ask:for_orgs");
  const flagEnabled = userEnabled || orgEnabled;

  // Both organization setting and feature flags must be enabled
  return !ask2Enabled && Boolean(orgSettingEnabled && flagEnabled);
};
