import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const SortIconDescending = (props: IconProps): JSX.Element => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9323 6.19708C18.4155 6.19708 18.8073 6.58883 18.8073 7.07208V15.3834L21.3814 12.8093C21.7231 12.4676 22.2771 12.4676 22.6188 12.8093C22.9605 13.151 22.9605 13.705 22.6188 14.0467L18.551 18.1145C18.3869 18.2786 18.1644 18.3708 17.9323 18.3708C17.7002 18.3708 17.4777 18.2786 17.3136 18.1145L13.2458 14.0467C12.9041 13.705 12.9041 13.151 13.2458 12.8093C13.5875 12.4676 14.1415 12.4676 14.4832 12.8093L17.0573 15.3834V7.07208C17.0573 6.58883 17.449 6.19708 17.9323 6.19708Z"
      fill="#808B97"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.875C2 6.39175 2.39175 6 2.875 6L11.9725 6C12.4557 6 12.8475 6.39175 12.8475 6.875C12.8475 7.35825 12.4557 7.75 11.9725 7.75L2.875 7.75C2.39175 7.75 2 7.35825 2 6.875Z"
      fill="#808B97"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 10.9428C2 10.4596 2.39175 10.0678 2.875 10.0678L9.26059 10.0678C9.74384 10.0678 10.1356 10.4596 10.1356 10.9428C10.1356 11.4261 9.74384 11.8178 9.26059 11.8178L2.875 11.8178C2.39175 11.8178 2 11.4261 2 10.9428Z"
      fill="#808B97"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 15.0106C2 14.5274 2.39175 14.1356 2.875 14.1356L6.54873 14.1356C7.03198 14.1356 7.42373 14.5274 7.42373 15.0106C7.42373 15.4939 7.03198 15.8856 6.54873 15.8856L2.875 15.8856C2.39175 15.8856 2 15.4939 2 15.0106Z"
      fill="#808B97"
    />
  </Icon>
);

export const SortIconAscending = (props: IconProps): JSX.Element => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9323 18.1737C18.4155 18.1737 18.8073 17.782 18.8073 17.2987V8.98741L21.3814 11.5615C21.7231 11.9032 22.2771 11.9032 22.6188 11.5615C22.9605 11.2198 22.9605 10.6658 22.6188 10.3241L18.551 6.25626C18.3869 6.09216 18.1644 5.99998 17.9323 5.99998C17.7002 5.99998 17.4777 6.09216 17.3136 6.25626L13.2458 10.3241C12.9041 10.6658 12.9041 11.2198 13.2458 11.5615C13.5875 11.9032 14.1415 11.9032 14.4832 11.5615L17.0573 8.98741V17.2987C17.0573 17.782 17.449 18.1737 17.9323 18.1737Z"
      fill="#808B97"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 17.4958C2 17.979 2.39175 18.3708 2.875 18.3708L11.9725 18.3708C12.4557 18.3708 12.8475 17.979 12.8475 17.4958C12.8475 17.0125 12.4557 16.6208 11.9725 16.6208L2.875 16.6208C2.39175 16.6208 2 17.0125 2 17.4958Z"
      fill="#808B97"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 13.428C2 13.9112 2.39175 14.303 2.875 14.303L9.26059 14.303C9.74384 14.303 10.1356 13.9112 10.1356 13.428C10.1356 12.9447 9.74384 12.553 9.26059 12.553L2.875 12.553C2.39175 12.553 2 12.9447 2 13.428Z"
      fill="#808B97"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9.36017C2 9.84342 2.39175 10.2352 2.875 10.2352L6.54873 10.2352C7.03198 10.2352 7.42373 9.84342 7.42373 9.36017C7.42373 8.87692 7.03198 8.48517 6.54873 8.48517L2.875 8.48517C2.39175 8.48517 2 8.87692 2 9.36017Z"
      fill="#808B97"
    />
  </Icon>
);
