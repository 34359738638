import { Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { IoMdClose } from "react-icons/io";

import { Button, IconButton } from "../../../components";
import useIsOverflowing from "../../../hooks/useIsOverflowing";
import SharedWithAvatar from "./SharedWithAvatar";
import { ShareUser } from "./types";

type UserShareItemProps = {
  user: ShareUser;
  primaryText: string;
  secondaryText: string;
  renewExternalShare?(): void;
  removeShare?(): void;
  index: number;
};

const UserShareItem: React.FC<UserShareItemProps> = ({
  user,
  primaryText,
  secondaryText,
  renewExternalShare,
  removeShare,
  index,
}) => {
  const { ref: primaryTextRef, isOverflowing: primaryTextIsOverflowing } =
    useIsOverflowing<HTMLParagraphElement>({ width: true });

  return (
    <Flex
      data-testid={`share[${index}]`}
      alignItems="center"
      py="2"
      pl="3"
      pr="4"
      mb="1"
      borderRadius="lg"
      _hover={{ bg: "gray.50" }}
    >
      <SharedWithAvatar user={user} w={6} h={6} fontSize="10px" />

      <Flex ml="3" flex="1" flexDir="column" minW="0">
        <Tooltip
          label={primaryTextIsOverflowing ? primaryText : undefined}
          openDelay={300}
        >
          <Text
            ref={primaryTextRef}
            mb="1px"
            color="gray.900"
            fontSize="sm"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {primaryText}
          </Text>
        </Tooltip>
        <Text color="gray.500" fontSize="xs">
          {secondaryText}
        </Text>
      </Flex>

      {renewExternalShare && (
        <Button ml="2" size="sm" variant="ghost" onClick={renewExternalShare}>
          Regrant access
        </Button>
      )}

      {removeShare && (
        <IconButton
          size="xs"
          data-testid={`remove-share[${index}]`}
          aria-label="Remove shared user"
          variant="none"
          icon={<IoMdClose />}
          ml="1"
          onClick={removeShare}
        />
      )}
    </Flex>
  );
};

export default UserShareItem;
