import { parsePhoneNumber } from "./phone";
import { Conditional } from "./types";

export function isString(s: any): s is string {
  return typeof s === "string";
}

export const formatPhoneNumber = (
  phoneNumber: string,
  extension?: string | null
): string => {
  try {
    const parsedNumber = parsePhoneNumber(phoneNumber);
    if (!parsedNumber?.isPossible()) {
      throw new Error(`Invalid phone number format: ${phoneNumber}`);
    }

    let formattedNumber;
    if (
      parsedNumber.country === "US" ||
      parsedNumber.countryCallingCode === "1"
    ) {
      formattedNumber = parsedNumber.formatNational();
    } else {
      formattedNumber = parsedNumber.formatInternational();
    }

    if (extension) {
      formattedNumber += ` ext. ${extension}`;
    }

    return formattedNumber;
  } catch (e) {
    throw new Error(`Invalid phone number format: ${phoneNumber}`);
  }
};

export const formatKeywordList = (
  keywordList: string[],
  conditional?: Conditional
): string => {
  let joinStr = "and";
  if (conditional === Conditional.OR) {
    joinStr = "or";
  }
  return keywordList.reduce((previous, current, idx) => {
    if (idx === 0) {
      return `"${current}"`;
    }
    if (idx === keywordList.length - 1 && keywordList.length > 2) {
      return `${previous}, ${joinStr} "${current}"`;
    }
    if (keywordList.length === 2) {
      return `${previous} ${joinStr} "${current}"`;
    }
    return `${previous}, "${current}"`;
  }, "");
};

export const formatUserRolePermissions = (
  permissions?: readonly string[]
): string => {
  let formatted = permissions
    ?.map((p) => {
      if (p === "MANAGE_HIRING_TEAM") {
        return "manage hiring teams";
      }
      return `${p.toLowerCase().replace(/_/g, " ")}`;
    })
    .join(", ");
  if (permissions?.length || 0 > 1) {
    formatted += ",";
  }

  if (formatted) {
    return `Can ${formatted} and access their own interviews`;
  }

  return `Can access their own interviews`;
};

export const formatPositionRolePermissions = (
  permissions?: readonly string[]
): string => {
  let formatted = permissions
    ?.map((p) => {
      return `${p?.toLowerCase().replace(/_/g, " ")}`;
    })
    .join(", ");
  if (permissions?.length || 0 > 1) {
    formatted += ",";
  }

  if (formatted) {
    return `Can ${formatted} and access their own interviews`;
  }

  return `Can access their own interviews`;
};

export const formatPlanUserRolePermissions = (
  permissions?: readonly string[]
): string => {
  let formatted = permissions
    ?.map((p) => {
      return `${p.toLowerCase().replace(/_/g, " ")}`;
    })
    .join(", ");
  if (permissions?.length || 0 > 1) {
    formatted += ",";
  }

  if (formatted) {
    return `Can ${formatted}`;
  }

  return `Cannot access BrightHire Plan`;
};

export const snakeCaseToTitleCase = (string: string): string => {
  return string
    .split("_")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    })
    .join(" ");
};

export const snakeCaseToSentenceCase = (string: string): string => {
  if (!string) return "";
  const words = string
    .split("_")
    .map((word) => word.toLowerCase())
    .join(" ");
  return words.charAt(0).toUpperCase() + words.slice(1);
};

/**
 * Convert a string to Title Case
 */
export const stringToTitleCase = (str: string): string => {
  return str
    .replace(/[-_/]/g, " ")
    .replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .trim();
};

/**
 * Convert a string to Sentence case
 */
export const sentenceCase = (s: string): string =>
  s.replace(/^([a-z])/i, (c) => c.toUpperCase());

export const splitName = (name: string): [string, string] => {
  const splitString = name.split(/\s+/);
  let firstName;
  let lastName = "";
  if (splitString.length < 3) {
    [firstName, lastName] = splitString;
    if (lastName === undefined) {
      lastName = "";
    }
  } else {
    firstName = splitString.slice(0, splitString.length - 1).join(" ");
    lastName = splitString[splitString.length - 1];
  }
  return [firstName, lastName];
};

export const escapeRegExp = (text: string): string => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

/**
 * Uppercases the first letter and lowercases the rest.
 */
export const uppercaseFirstLetterLowercaseRest = (text: string): string => {
  const lower = text.toLowerCase();
  return `${lower[0].toUpperCase()}${lower.slice(1)}`;
};

export const truncateString = (string: string, maxLength = 60): string => {
  if (string.length > maxLength) {
    return `${string.slice(0, maxLength)}…`;
  }
  return string;
};
