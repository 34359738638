import { useCallback, useState } from "react";

export type StreamingCompletion = {
  text?: string;
  loading: boolean;
  error?: string;
};

/**
 * Streams a free text response from the AI model for the onboarding plan.
 */
const useOnboardingPlanStream = (
  candidateId: string,
  positionId: string | null
): {
  streamingCompletion: StreamingCompletion;
  run(userPrompt: string): void;
} => {
  const [streamingCompletion, setStreamingCompletion] =
    useState<StreamingCompletion>({
      loading: false,
    });
  const requestPath = `/candidate/${candidateId}/stream_onboarding_plan`;

  const handleError = (error: string): void => {
    setStreamingCompletion({
      loading: false,
      error,
      text: error,
    });
  };

  const run = useCallback(
    (userPrompt: string) => {
      if (userPrompt === "" || userPrompt === null) {
        setStreamingCompletion({ loading: true });
        return;
      }
      setStreamingCompletion({ loading: true });
      const controller = new AbortController();
      const { signal } = controller;
      fetch(requestPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          candidateId,
          positionId,
          userPrompt,
        }),
        signal,
      })
        .then(async (response) => {
          if (!response?.ok || !response.body) {
            if (response?.status === 402) {
              handleError(
                "You have reached your daily limit of AI requests, please try again later."
              );
            } else {
              handleError("Something went wrong, please try again later.");
            }
            return;
          }
          const reader = response.body.getReader();
          let concatenatedStream = "";
          const decoder = new TextDecoder();
          try {
            while (!signal.aborted) {
              // eslint-disable-next-line no-await-in-loop
              const { value, done } = await reader.read();
              if (done) break;
              const text = decoder.decode(value);
              concatenatedStream += text;
              setStreamingCompletion({
                text: concatenatedStream,
                loading: true,
              });
            }
          } catch (error) {
            handleError("Error reading plan");
            return;
          }
          setStreamingCompletion({
            text: concatenatedStream,
            loading: false,
          });
        })
        .catch((error) => {
          handleError("Error fetching onboarding plan");
        });
      return () => controller.abort();
    },
    [candidateId, positionId, requestPath]
  );

  return {
    streamingCompletion,
    run,
  };
};

export default useOnboardingPlanStream;
