import { Box, StyleProps } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { TranscriptSegmentFragment } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import ClipSegment from "./ClipSegment";
import { ClipPlayRange, NarrowMediaPlayer } from "./types";
import useControlledScroll from "./useControlledScroll";
import { findSelectedSegments } from "./utils";

type ClipTranscriptProps = StyleProps & {
  transcript: TranscriptSegmentFragment[];
  playRange: ClipPlayRange;
  speakerLabels: string[];
  narrowMediaPlayer: NarrowMediaPlayer;
  setPlayRange: (fn: (prev: ClipPlayRange) => ClipPlayRange) => void;
  visible: boolean;
};

const ClipTranscript: React.FC<ClipTranscriptProps> = ({
  transcript,
  playRange,
  speakerLabels,
  setPlayRange,
  narrowMediaPlayer,
  visible,
  ...styles
}) => {
  const dragToSelectEnabled = useFeatureFlag("clips:drag-to-select");
  const transcriptRef = useRef<HTMLDivElement>(null);
  const { scrollTo } = useControlledScroll({
    ref: transcriptRef,
    playing: narrowMediaPlayer.playing,
  });

  // Whether native text selection is active. Used as a prop to trigger updates downstream.
  const [activeBrowserSelection, setActiveBrowserSelection] = useState(false);

  // Update clip bounds based on browser native text selection
  useEffect(() => {
    if (!dragToSelectEnabled) {
      return;
    }
    if (!transcriptRef.current) {
      return;
    }
    const listener = (): void => {
      const selection = window.getSelection();
      if (!selection?.anchorNode || !selection.focusNode) {
        return;
      }
      const { anchorNode, focusNode } = selection;
      const anchorWord = findParentWordElement(anchorNode);
      const focusWord = findParentWordElement(focusNode);
      if (!anchorWord || !focusWord) {
        return;
      }
      const anchorStart = parseFloat(
        anchorWord.getAttribute("data-starttime") || "NaN"
      );
      const anchorEnd = parseFloat(
        anchorWord.getAttribute("data-endtime") || "NaN"
      );
      const focusStart = parseFloat(
        focusWord.getAttribute("data-starttime") || "NaN"
      );
      const focusEnd = parseFloat(
        focusWord.getAttribute("data-endtime") || "NaN"
      );

      // Sometimes selection enters an empty state with nothing selected. Ignore
      // such updates to avoid breaking the current selection.
      if (selection.isCollapsed) {
        return;
      }

      // Require all bounding word times to be defined
      if (
        Number.isNaN(anchorStart) ||
        Number.isNaN(anchorEnd) ||
        Number.isNaN(focusStart) ||
        Number.isNaN(focusEnd)
      ) {
        return;
      }

      // Selections can go backwards, so find the real start and real end
      const startTime = Math.min(anchorStart, focusStart);
      const endTime = Math.max(anchorEnd, focusEnd);

      if (startTime && endTime) {
        setActiveBrowserSelection(true);
        // A video playing while defining bounds results in a stuttering playback, so pause
        narrowMediaPlayer.pause();
        setPlayRange((prev) => ({
          start: startTime,
          play: startTime,
          end: endTime,
        }));
      }
    };
    document.addEventListener("selectionchange", listener);
    return () => {
      document.removeEventListener("selectionchange", listener);
    };
  }, [transcriptRef.current]);

  const [dragging, setDragging] = useState<null | "start" | "end">(null);

  const adjustBounds = useCallback(
    (wordStartTime: number, wordEndTime: number): void => {
      if (!dragging) {
        return;
      }
      if (dragging === "start") {
        setPlayRange((prev) => {
          if (wordStartTime > prev.end) {
            return prev;
          }
          const newStartTime =
            wordStartTime > prev.end ? prev.start : wordStartTime;
          return {
            play: newStartTime,
            start: newStartTime,
            end: Math.max(prev.end, wordEndTime),
          };
        });
      } else if (dragging === "end") {
        setPlayRange((prev) => {
          if (wordEndTime < prev.start) {
            return prev;
          }
          return {
            play: Math.min(prev.play, wordStartTime),
            start: Math.min(prev.start, wordEndTime),
            end: wordEndTime,
          };
        });
      }
    },
    [dragging, setPlayRange]
  );

  const onDragStart = useCallback(
    (position: "start" | "end") => {
      // Using the edge draggers should clear any lingering text selection,
      // as otherwise there can be inconsistent states.
      window.getSelection()?.empty();
      // A video playing while defining bounds results in a stuttering playback, so pause
      narrowMediaPlayer.pause();
      setActiveBrowserSelection(false);
      setDragging(position);
    },
    [setDragging]
  );

  if (transcript.length === 0) {
    return (
      <Box px="8" py="4" textAlign="center" {...styles}>
        No transcript is available.
      </Box>
    );
  }

  const transcriptSelection = findSelectedSegments(transcript, playRange);
  return (
    <Box
      overflow="auto"
      ref={transcriptRef}
      position="relative"
      borderColor="gray.100"
      minH={visible ? "150px" : 0}
      maxH={visible ? "300px" : 0}
      onMouseUp={() => {
        setDragging(null);
        window.getSelection()?.empty();
        setActiveBrowserSelection(false);
      }}
      cursor={dragging ? "grabbing" : undefined}
      px="8"
      boxShadow="inset 0 10px 20px 0px rgb(0 0 0 / 4%), inset 0 -10px 20px -7px rgb(0 0 0 / 4%)"
      {...styles}
    >
      {transcript.map((segment, segmentIndex) => {
        const boundsOrPlaySegment = [
          transcriptSelection.startSegmentIdx,
          transcriptSelection.playSegmentIdx,
          transcriptSelection.endSegmentIdx,
        ].includes(segmentIndex);
        const inactive =
          segmentIndex < transcriptSelection.startSegmentIdx ||
          segmentIndex > transcriptSelection.endSegmentIdx;
        return (
          <ClipSegment
            key={segment.id}
            segment={segment}
            speakerLabel={speakerLabels[segment.speakerTag - 1] || ""}
            inactive={inactive}
            setPlayRange={setPlayRange}
            player={narrowMediaPlayer}
            scrollTo={scrollTo}
            segmentIndex={segmentIndex}
            onMouseOverWord={adjustBounds}
            onSelectionEdgeDragStart={onDragStart}
            dragging={dragging !== null}
            playRange={boundsOrPlaySegment ? playRange : undefined}
            activeBrowserSelection={
              boundsOrPlaySegment ? activeBrowserSelection : undefined
            }
            selectionStartWord={
              segmentIndex === transcriptSelection.startSegmentIdx
                ? transcriptSelection.startWord
                : undefined
            }
            selectionEndWord={
              segmentIndex === transcriptSelection.endSegmentIdx
                ? transcriptSelection.endWord
                : undefined
            }
          />
        );
      })}
    </Box>
  );
};

/**
 * Walk up the DOM to find the nearest ancestor with the expected data attributes
 * for a clip transcript word. Used to find the start time and end time of a given
 * transcript selection.
 */
const findParentWordElement = (node: Node): Element | undefined => {
  let el = node.parentElement;
  while (el) {
    if (el.hasAttribute("data-starttime")) {
      return el;
    }
    el = el.parentElement;
  }
  if (node.parentElement) {
    return node.parentElement;
  }
  return undefined;
};

export default ClipTranscript;
