import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Icon,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoInformationCircle } from "react-icons/io5";

import { Button } from "../../../components";
import { pluck } from "../../../utils/array";
import {
  EXTERNAL_SHARE_DURATION_VALUES,
  remainingShareDuration,
} from "../../../utils/external-shares";
import CopyCallLinkButton from "./CopyCallLinkButton";
import { SelectShareUsers } from "./SelectShareUsers";
import SharedWithStatic from "./StaticMessage";
import {
  ExternalShare,
  isExternalShare,
  isExternalUser,
  isInternalShare,
  isInternalUser,
  Share,
  ShareModalProps,
  ShareUser,
} from "./types";
import UserShareItem from "./UserShareItem";
import VisibilitySelect from "./VisibilitySelect";

const ShareModal: React.FC<ShareModalProps> = ({
  trainingProgramCount = 0,
  removeExternalShare,
  renewExternalShare,
  onChangeVisibility,
  defaultTitle,
  includeTitleInput = false,
  canShareExternal = false,
  visibilityLevels,
  optionsLoading,
  shareableUsers,
  maxSelection,
  shareLoading,
  onSelection: onSelectionProp,
  removeShare,
  sharedWith: sharedWithProp = [],
  visibleTo: visibleToData,
  visibility,
  positionId,
  callId,
  clipId,
  canShare,
  onShare,
  onClose,
  modalTitle = "Share Recording",
  externalShareDuration,
  sharedWithStatic,
  copyLink,
  infoText,
}) => {
  const [selectedUsers, setSelectedUsers] = useState<ShareUser[]>([]);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState(defaultTitle || "");
  const [externalShareToRemove, setExternalShareToRemove] =
    useState<ExternalShare | null>(null);

  const hasSelection = !!selectedUsers?.length;
  const hasExternalSelection =
    canShareExternal && selectedUsers.some(isExternalUser);

  /** e.g. "for 3 days" */
  let externalShareDurationText = EXTERNAL_SHARE_DURATION_VALUES.find(
    ({ value }) => value === externalShareDuration
  )?.name;
  if (externalShareDurationText) {
    externalShareDurationText = `for ${externalShareDurationText} `;
  } else {
    externalShareDurationText = "";
  }

  const onSelection = (selection: ShareUser[]): void => {
    setSelectedUsers(selection);
    onSelectionProp?.(selection);
  };

  const cancel = (): void => {
    onSelection([]);
    setMessage("");
  };

  const share = (): void => {
    const internalShares = selectedUsers.filter(isInternalUser);
    const externalShares = selectedUsers.filter(isExternalUser);
    onShare({
      internalIds: pluck(internalShares, "id"),
      externalEmails: pluck(externalShares, "email"),
      message,
      title,
    });
  };

  const primaryText = (share: Share): string => {
    if (isInternalShare(share)) return share.sharedTo.fullName;
    if (isExternalShare(share)) return share.sharedTo.email;
    return "";
  };

  const secondaryText = (share: Share): string => {
    if (isInternalShare(share)) return "Invited";
    if (isExternalShare(share))
      return remainingShareDuration(share.daysRemaining);
    return "";
  };

  const isExpired = (share: Share): boolean =>
    isExternalShare(share) && share.isExpired;

  const totalVisibleTo = visibleToData ? visibleToData.resultCount : 0;
  const visibleTo = visibleToData ? visibleToData.results : [];

  if (externalShareToRemove) {
    return (
      <>
        <ModalHeader
          color="gray.900"
          fontWeight="500"
          fontSize="lg"
          borderBottom="1px solid"
          borderBottomColor="gray.100"
        >
          Remove Access to this Recording
        </ModalHeader>
        <ModalCloseButton top="6" right="5" />
        <ModalBody pt="8" pb="0">
          <Text color="gray.700" fontWeight="400" fontSize="sm">
            Are you sure you want to remove access to this recording for{" "}
            {externalShareToRemove.sharedTo.email}?
          </Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm" spacing="2">
            <Button
              variant="ghost"
              fontWeight="500"
              onClick={() => setExternalShareToRemove(null)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="danger"
              fontSize="sm"
              px="3"
              py="3"
              fontWeight="500"
              onClick={async () => {
                await removeExternalShare?.(externalShareToRemove);
                setExternalShareToRemove(null);
              }}
            >
              Remove
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </>
    );
  }

  const showVisibility = visibility && visibilityLevels && onChangeVisibility;
  const sharedWith = canShareExternal
    ? sharedWithProp
    : sharedWithProp.filter(isInternalShare);
  const hasBody = !!(showVisibility || sharedWith.length || visibleTo.length);

  return (
    <>
      <ModalHeader
        p="6"
        borderBottom="1px solid"
        borderColor="gray.100"
        fontSize="xl"
        fontWeight="medium"
      >
        {modalTitle}
      </ModalHeader>
      <ModalCloseButton top="6" right="5" />
      <Box mx="8" mt="6" mb="0">
        {!canShare ? (
          <>
            <Flex
              alignItems="center"
              bg="gray.50"
              borderColor="gray.100"
              borderWidth="1px"
              borderRadius="base"
              fontSize="sm"
              pl="4"
              py="10px"
              pr="3"
              mb="8"
            >
              <Icon
                as={IoInformationCircle}
                color="orange.400"
                w={5}
                h={5}
                mr="14px"
              />
              You can share this recording link only with the users below.
              Contact a site admin to change your permission level.
            </Flex>
            <Divider />
          </>
        ) : (
          <SelectShareUsers
            canShareExternal={canShareExternal}
            optionsLoading={optionsLoading}
            shareableUsers={shareableUsers}
            selectedUsers={selectedUsers}
            maxSelection={maxSelection}
            onSelection={onSelection}
          />
        )}
      </Box>

      <ModalBody
        pt="5"
        pr="7"
        pb={hasBody ? 2 : 0}
        mr="1"
        overflowY="auto"
        maxH={350}
      >
        {infoText && (
          <Text fontSize="sm" color="gray.500" mb="4">
            {infoText}
          </Text>
        )}
        {hasSelection ? (
          <>
            {hasExternalSelection && (
              <Flex
                alignItems="center"
                mb="6"
                bg="gray.50"
                borderRadius="base"
                border="1px solid"
                borderColor="gray.100"
                px="4"
                py="2.5"
              >
                <Text fontSize="sm">
                  You are sharing with users that are not in your BrightHire org
                  (shown in orange). They will have limited access{" "}
                  {externalShareDurationText} to the recording, transcript,
                  currently selected AI notes format, and points of interest.
                  They won&apos;t see any internal notes or reactions, and they
                  cannot invite others to view. Please exercise caution when
                  sharing externally.
                </Text>
              </Flex>
            )}

            {includeTitleInput && (
              <Input
                placeholder="Title (optional)"
                value={title}
                mb="5"
                data-testid="share-title"
                onChange={(e) => setTitle(e.target.value)}
              />
            )}

            <Textarea
              data-testid="share-message"
              maxH="120"
              placeholder="Add a message (optional)"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </>
        ) : (
          hasBody && (
            <>
              <Text fontWeight="medium" fontSize="sm" mb="4">
                {showVisibility || sharedWithStatic
                  ? "Who can view it?"
                  : "Shared with"}
              </Text>

              {showVisibility && canShare && (
                <VisibilitySelect
                  onChangeVisibility={onChangeVisibility}
                  visibilityLevels={visibilityLevels}
                  visibility={visibility}
                />
              )}

              {sharedWith.map((share, index) => (
                <UserShareItem
                  key={share.sharedTo?.id}
                  user={share.sharedTo}
                  primaryText={primaryText(share)}
                  secondaryText={secondaryText(share)}
                  renewExternalShare={
                    isExpired(share)
                      ? () => renewExternalShare?.(share as ExternalShare)
                      : undefined
                  }
                  removeShare={() => {
                    if (isExternalShare(share)) {
                      setExternalShareToRemove(share);
                    } else if (isInternalShare(share)) {
                      removeShare?.(share);
                    }
                  }}
                  index={index}
                />
              ))}

              {visibleTo.map(({ role, user }, index) => (
                <UserShareItem
                  key={user.id}
                  user={user}
                  primaryText={user.fullName}
                  secondaryText={role}
                  index={sharedWith.length + index}
                />
              ))}

              {showVisibility && !sharedWithStatic && (
                <SharedWithStatic
                  visibility={visibility}
                  trainingProgramCount={trainingProgramCount}
                  numNotShown={totalVisibleTo - visibleTo.length}
                  positionId={positionId}
                />
              )}

              {sharedWithStatic}
            </>
          )
        )}
      </ModalBody>

      {!hasSelection && <Divider mx="8" w="auto" />}

      <ModalFooter py="4">
        {hasSelection ? (
          <ButtonGroup size="sm" spacing="2">
            <Button variant="outline" onClick={cancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              data-testid="share-submit-button"
              isLoading={shareLoading}
              onClick={share}
            >
              Share
            </Button>
          </ButtonGroup>
        ) : (
          <>
            <Button
              variant="white"
              fontSize="sm"
              fontWeight="medium"
              onClick={onClose}
              mr="auto"
            >
              Cancel
            </Button>
            {callId ? (
              <CopyCallLinkButton
                callId={callId}
                clipId={clipId}
                ml="auto"
                mr="6"
              />
            ) : (
              copyLink
            )}

            <Button px="5" fontWeight="medium" fontSize="sm" onClick={onClose}>
              Done
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default ShareModal;
