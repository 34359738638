import {
  Box,
  Flex,
  FlexProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProps,
} from "@chakra-ui/react";
import React, { PropsWithChildren, useLayoutEffect, useState } from "react";

import { asArray } from "../../../utils/array";

interface SidebarTabProps<T extends string> {
  id: T;
  displayName: JSX.Element | string;
  padBottom?: true;
}

export const SidebarTab = <T extends string>({
  children,
  padBottom,
}: PropsWithChildren<SidebarTabProps<T>>): JSX.Element => {
  if (padBottom) return <Box pb="16">{children}</Box>;
  return <>{children}</>;
};

type SidebarTab<T extends string> = React.ReactElement<SidebarTabProps<T>>;
type Child<T extends string> =
  | SidebarTab<T>
  | string
  | number
  | boolean
  | null
  | undefined;

interface SidebarTabsProps<T extends string>
  extends Omit<TabsProps, "index" | "onChange" | "defaultIndex"> {
  currentTab?: T;
  onTabChange?(tab: T): void;
  children: Child<T>[] | Child<T>;
  tabListWrapperProps?: FlexProps;
}

const SidebarTabs = <T extends string>({
  currentTab,
  onTabChange,
  isLazy = true,
  lazyBehavior = "keepMounted",
  children,
  tabListWrapperProps,
  ...tabsProps
}: SidebarTabsProps<T>): JSX.Element => {
  const { tabs, tabIds, tabNames } = useTabData(children);

  const [tabIndex, setTabIndexState] = useState(0);
  const setTabIndex = (idx: number): void => {
    if (idx !== tabIndex) {
      setTabIndexState(idx);
      onTabChange?.(tabIds[idx]);
    }
  };

  // Set tab based on the `currentTab` prop
  // useLayoutEffect to avoid one frame of rendering the prior tab
  useLayoutEffect(() => {
    if (currentTab) {
      const idx = tabIds.findIndex((tabId) => tabId === currentTab);
      if (idx !== -1) {
        setTabIndex(idx);
      }
    }
  }, [currentTab, tabIds]);

  return (
    <Tabs
      display="flex"
      flexDir="column"
      height="100%"
      index={tabIndex}
      onChange={setTabIndex}
      isLazy={isLazy}
      lazyBehavior={lazyBehavior}
      {...tabsProps}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        height="16"
        px="5"
        borderBottom="1px"
        borderColor="gray.100"
        {...tabListWrapperProps}
      >
        <TabList
          alignItems="center"
          justifyContent="center"
          borderBottom="none"
          borderRadius="lg"
          bg="blackAlpha.100"
          maxWidth="540px"
          flex="1"
          p="1"
          gap="1"
        >
          {tabNames.map((tabName, idx) => {
            return (
              <Tab
                _hover={{
                  backgroundColor: "white",
                }}
                _selected={{
                  background: "white",
                  fontWeight: "600",
                }}
                whiteSpace="nowrap"
                borderRadius="lg"
                color="gray.900 !important"
                data-testid={`${tabIds[idx]}-tab`}
                h="8"
                key={tabIds[idx]}
                py="0"
                px="2"
                flex="1"
                fontWeight="500"
                mb="0"
              >
                <Box fontSize="14px" lineHeight="20px">
                  {typeof tabName === "function"
                    ? tabName({ isSelected: idx === tabIndex })
                    : tabName}
                </Box>
              </Tab>
            );
          })}
        </TabList>
      </Flex>

      <TabPanels overflowY="auto" flex="1">
        {tabs.map((tab, idx) => (
          <TabPanel key={tabIds[idx]} h="100%">
            {tab}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default SidebarTabs;

/**
 * Filter out falsy children and return tabs along with names and ids
 */
function useTabData<T extends string>(
  children: Child<T>[] | Child<T>
): {
  tabs: SidebarTab<T>[];
  tabIds: T[];
  tabNames: (string | JSX.Element | React.FC<{ isSelected: boolean }>)[];
} {
  const tabs = asArray(children).filter((c) => !!c) as SidebarTab<T>[];

  const [tabIds, tabNames] = tabs.reduce(
    ([ids, names], tab) => [
      ids.concat(tab.props.id),
      names.concat(tab.props.displayName),
    ],
    [[], []] as [
      T[],
      (JSX.Element | string | React.FC<{ isSelected: boolean }>)[]
    ]
  );

  return { tabs, tabIds, tabNames };
}
