import { useCallback, useState } from "react";

export enum AskChatRole {
  Assistant = "assistant",
  User = "user",
}

export type AskChatMessage = {
  role: AskChatRole;
  content: string; // text to send to AI
  userContent: string; // nice version of text shown to user, e.g. "write an email" vs. underlying prompt
};

export type AskChat = {
  chatId: string | undefined;
  messages: AskChatMessage[];
};

/**
 * Internal state for the latest message sent to the AI.
 */
type Completion = {
  text?: string;
  loading: boolean;
  error?: string;
};

const chatIdFromCompletion = (completion: Completion): string | undefined => {
  if (completion.text) {
    try {
      const parsed = JSON.parse(completion.text);
      return parsed.chat_id;
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

const useAskChatJson = (
  requestPath: string,
  args: { [key: string]: string | null }
): {
  chat: AskChat;
  addUserMessage: (content: string, userContent: string) => Promise<void>;
  busy: boolean;
  reset: () => void;
} => {
  const [chat, setChat] = useState<AskChat>({
    chatId: undefined,
    messages: [],
  });
  const [latestMessage, setLatestMessage] = useState<Completion>({
    loading: false,
  });

  const updateLatestMessage = (completion: Completion): void => {
    setChat((prevChat) => {
      const completionChatId = chatIdFromCompletion(completion);
      const newChat = {
        ...prevChat,
        chatId: prevChat.chatId || completionChatId,
        messages: [...prevChat.messages],
      };
      const newChatMessages = newChat.messages;
      newChatMessages[newChatMessages.length - 1] = {
        role: AskChatRole.Assistant,
        content: completion.text || "",
        userContent: completion.text || "",
      };
      return newChat;
    });
    setLatestMessage(completion);
  };

  const handleError = (error: string): void => {
    updateLatestMessage({
      loading: false,
      error,
      text: error,
    });
  };

  const addUserMessage = useCallback(
    async (content: string, userContent: string): Promise<void> => {
      try {
        const newChat: AskChat = {
          ...chat,
          messages: [
            ...chat.messages,
            { role: AskChatRole.User, content, userContent },
            { role: AskChatRole.Assistant, content: "", userContent: "" },
          ],
        };
        setChat(newChat);
        setLatestMessage({ loading: true });

        const response = await fetch(requestPath, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages: newChat.messages.slice(0, -1),
            chatId: chat.chatId,
            ...args,
          }),
        });
        if (!response.ok || !response.body) {
          if (response.status === 402) {
            handleError(
              "You have reached your daily limit of AI requests, please try again later."
            );
          } else {
            handleError("Something went wrong, please try again later.");
          }
          return;
        }
        const reader = response.body.getReader();
        let finalText = "";
        // eslint-disable-next-line no-constant-condition
        while (true) {
          // eslint-disable-next-line no-await-in-loop
          const { value, done } = await reader.read();
          if (done) break;
          const text = new TextDecoder().decode(value);
          const latestMessage = keepLatestConcatenatedMessage(text);
          finalText = latestMessage;
          updateLatestMessage({
            text: latestMessage,
            loading: true,
          });
        }
        updateLatestMessage({
          text: finalText,
          loading: false,
        });
      } catch (e) {
        handleError("Something went wrong, please try again later.");
      }
    },
    [chat, requestPath, JSON.stringify(args)]
  );
  const reset = useCallback(() => {
    setChat({ chatId: undefined, messages: [] });
  }, [setChat]);
  return {
    chat,
    addUserMessage,
    busy: latestMessage.loading,
    reset,
  };
};

/**
 * Sometimes multiple separate streaming JSON messages are concatenated.
 * This splits them and returns the latest one.
 */
const keepLatestConcatenatedMessage = (jsonText: string): string => {
  const splitPattern = '{"notes":';
  const parts = jsonText.split(splitPattern);
  return `${splitPattern}${parts[parts.length - 1]}`;
};

export default useAskChatJson;
