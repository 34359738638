import * as Sentry from "@sentry/browser";
import { useState } from "react";

import { errorToast, successToast, useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  useCallShareLazyQuery,
  useRemoveExternalCallShareMutation,
  useRemoveShareMutation,
  useRenewExternalCallShareMutation,
  useShareCallExternallyMutation,
  useShareCallMutation,
  useUpdateCallVisibilityMutation,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Share, ShareModalProps } from "./types";

type UseCallShareModalParams = {
  callId: string;
  onClose(): void;
};

type UseCallShareModal = Omit<
  ShareModalProps,
  "callId" | "onClose" | "visibility" | "visibilityLevels" | "positionId"
> & {
  getCallShareData(): Promise<any>;
  hasSelection: boolean;
  loading: boolean;
};

/**
 * Like useSharePlaylistModal() and useClipShareModal(), this hook sets
 * up all of the queries and mutations required to run the share modal for
 * a call and returns them as props that can be passed to the share modal
 */
export function useCallShareModal({
  callId,
  onClose,
}: UseCallShareModalParams): UseCallShareModal {
  const toast = useToast();
  const [hasSelection, setHasSelection] = useState(false);
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();

  // query data
  const [getCallShareData, { data, loading }] = useCallShareLazyQuery({
    variables: { id: callId },
    onError: (err) => {
      Sentry.captureException(err);
      onClose();
      errorToast(toast, `There was a problem sharing this interview`);
    },
  });

  const {
    trainingProgramCount,
    externalCallShares,
    canShare,
    canShareExternal,
    callShares,
    shareableUsers,
    visibleTo,
  } = data?.call ?? {
    canShare: false,
  };

  const internalShares: Share[] = callShares || [];
  const externalShares: Share[] = externalCallShares || [];
  const sharedWithUnique = externalShares
    .concat(internalShares)
    .filter(
      (share, index, shares) =>
        shares.findIndex((s) => s.sharedTo?.id === share.sharedTo?.id) === index
    );

  // visibility mutations
  const [updateCallVisibility] = useUpdateCallVisibilityMutation({
    onError: (err) => {
      errorToast(toast, `Error updating interview privacy: ${err.message}`);
    },
    onCompleted: (data) => {
      if (data?.updateCall) {
        successToast(toast, "Interview visibility updated.");
      }
    },
  });

  // share mutations
  const [shareCall, { loading: shareMutationLoading }] = useShareCallMutation();

  const [removeShare, { loading: removeShareLoading }] = useRemoveShareMutation(
    {
      onError: (err) => {
        errorToast(toast, `Failed to remove share: ${err.message}`);
      },
      onCompleted: (data) => {
        if (data?.removeShare) {
          successToast(toast, "Removed share");
        }
      },
    }
  );

  // external share mutations
  const [shareCallExternally, { loading: externalShareMutationLoading }] =
    useShareCallExternallyMutation();

  const [renewExternalShare] = useRenewExternalCallShareMutation({
    onError(err) {
      errorToast(toast, `Failed to renew external share: ${err.message}`);
    },
    onCompleted(data) {
      if (data.renewExternalCallShare) {
        successToast(toast, "Successfully renewed external share");
      }
    },
  });

  const [removeExternalShare, { loading: removeExternalShareLoading }] =
    useRemoveExternalCallShareMutation({
      onError: (err) => {
        errorToast(toast, `Failed to remove external share: ${err.message}`);
      },
      onCompleted: (data) => {
        if (data?.removeExternalCallShare) {
          successToast(toast, "Removed external share");
        }
      },
    });

  return {
    // these are used by the calling component
    getCallShareData,
    hasSelection,
    loading,

    // these are the relevant share modal props
    canShare,
    canShareExternal,
    externalShareDuration:
      currentUser.organization.externalShareDefaultDurationDays,
    trainingProgramCount,
    shareableUsers,
    sharedWith: sharedWithUnique,
    optionsLoading: loading,
    shareLoading: shareMutationLoading || externalShareMutationLoading,
    visibleTo,
    modalTitle: "Share Interview",

    onChangeVisibility(visibility) {
      sendGAEvent("update_visibility", "call_review", visibility);
      updateCallVisibility({
        variables: { id: callId, visibility },
      });
    },
    onSelection(selection) {
      setHasSelection(!!selection.length);
    },

    onShare({ internalIds, externalEmails, message }) {
      const mutations = [
        internalIds.length > 0 &&
          shareCall({
            variables: { id: callId, shareToUserIds: internalIds, message },
          }),
        externalEmails.length > 0 &&
          shareCallExternally({
            variables: { callId, shareToEmails: externalEmails, message },
          }),
      ];

      Promise.all(mutations)
        .then(([internalShare, externalShare]) => {
          if (externalShare)
            sendGAEvent("sharing_external", "call_review", "call_link");

          if (internalShare || externalShare) {
            successToast(toast, "Interview shared");
            onClose();
          }
        })
        .catch((err) => {
          errorToast(toast, `Failed to share interview: ${err.message}`);
        });
    },
    removeShare({ id }) {
      if (!removeShareLoading) {
        removeShare({ variables: { id } });
      }
    },

    renewExternalShare({ id }) {
      renewExternalShare({ variables: { id } });
    },
    async removeExternalShare({ id }) {
      if (!removeExternalShareLoading) {
        await removeExternalShare({ variables: { id } });
      }
    },
  };
}
