import { useCallback } from "react";

import { formatISODate } from "../../../utils/datetime";
import {
  AnalyticsDimension,
  DataPoint,
  MetricName,
  useAnalyticsBenchmarksQuery,
  useAnalyticsDataCsvLazyQuery,
  useAnalyticsDataQuery,
} from "../../graphql";
import { AnalyticsHeroType, pickHeroType } from "./AnalyticsHeroContent";
import { AnalyticsConfig, CommonQueryVariables } from "./types";
import useAnalyticsContext from "./useAnalyticsContext";
import { isScorecardMetric } from "./utils";

export type MetricBenchmarkRange = {
  metric: any;
  upperBound: number;
  lowerBound: number;
};

export type AnalyticsData = {
  dataPoints: DataPoint[] | undefined;
  heroType: AnalyticsHeroType | null;
  filtersAvailable: boolean;
  loading: boolean;
  commonQueryVariables: CommonQueryVariables;
  benchmarkRange?: MetricBenchmarkRange;
  csvLoading: boolean;
  downloadCSV: () => void;
};

const disabledMetrics: string | MetricName[] = [];

type Options = {
  skipAllQueries?: boolean;
};

/**
 * Query results and any error states related to analytics
 */
const useAnalyticsData = (
  analyticsConfig: AnalyticsConfig,
  options: Options
): AnalyticsData => {
  const { skipAllQueries } = options;
  const { metric } = analyticsConfig;
  const { selectedOrgId, atsDataState } = useAnalyticsContext();

  const commonQueryVariables: CommonQueryVariables = {
    metric,
    dateRangeStart: formatISODate(analyticsConfig.dateRange.value.start),
    dateRangeEnd: formatISODate(analyticsConfig.dateRange.value.end),
    organizationId: selectedOrgId,
    ...analyticsConfig.filters,
  };

  const analyticsDataQuery = useAnalyticsDataQuery({
    variables: {
      ...commonQueryVariables,
      metric,
      primaryDimension: analyticsConfig.primaryDimension.value,
      secondaryDimension: analyticsConfig.secondaryDimension.value,
    },
    skip: disabledMetrics?.includes(metric) || skipAllQueries,
  });
  const dataPoints = analyticsDataQuery.data?.analyticsData?.data;

  const [fetchCsv, { loading: csvLoading }] = useAnalyticsDataCsvLazyQuery({
    fetchPolicy: "network-only",
  });

  const downloadCSV = useCallback(() => {
    fetchCsv({
      variables: {
        ...commonQueryVariables,
        metric,
      },
      onCompleted(data) {
        const url = data?.analyticsDataCsv?.url;
        if (url) {
          const downloadLink = document.createElement("a");
          downloadLink.style.display = "none";
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      },
    });
  }, [metric, JSON.stringify(commonQueryVariables)]);

  // Lifetime interview count
  const lifetimeInterviewsMetric = isScorecardMetric(metric)
    ? MetricName.AtsTotalInterviews
    : MetricName.TotalInterviews;
  const visibleInterviewCountQuery = useAnalyticsDataQuery({
    variables: {
      metric: lifetimeInterviewsMetric,
      primaryDimension: AnalyticsDimension.Interviewer,
      secondaryDimension: AnalyticsDimension.None,
      departments: [],
      positions: [],
      interviewers: [],
      stages: [],
      dateRangeStart: `1901-01-01`,
      dateRangeEnd: formatISODate(new Date()),
      organizationId: selectedOrgId,
    },
    skip: skipAllQueries,
  });
  const visibleInterviewCount =
    visibleInterviewCountQuery.data?.analyticsData?.data?.reduce(
      (prev, cur) => prev + (cur.value || 0),
      0
    );

  // benchmarks
  const analyticsBenchmarksQuery = useAnalyticsBenchmarksQuery();
  const benchmarks = analyticsBenchmarksQuery.data?.benchmarks?.data;
  const benchmarkRange = benchmarks?.find((b) => b.metric === metric);

  const queriesLoadedAndNoData =
    !analyticsDataQuery.loading &&
    !visibleInterviewCountQuery.loading &&
    dataPoints?.length === 0;

  /** Whether the user sees no data, but could if they changed filters */
  const noDataBecauseOfFilters =
    queriesLoadedAndNoData && visibleInterviewCount !== 0;

  const heroType = pickHeroType(
    metric,
    Boolean(analyticsDataQuery.error) ||
      Boolean(visibleInterviewCountQuery.error),
    queriesLoadedAndNoData,
    visibleInterviewCount,
    atsDataState
  );

  return {
    dataPoints,
    heroType,
    downloadCSV,
    csvLoading,
    // Any hero display other than the one specifically about overly specific filters
    // means there is definitely no data that could be filtered.
    filtersAvailable: !heroType || noDataBecauseOfFilters,
    // If dataPoints is undefined or null the query is obviously loading. If data points are
    // defined, but empty, it could be either because of filters or the lack of interviews.
    // Show loading until we are sure.
    loading:
      dataPoints === undefined ||
      dataPoints === null ||
      // Because interviewer labels are used in drillDowns as soon as chart is
      // rendered, wait for interviewer labels to be available if interviewers are selected. In practice filters
      // load faster than data because they are a simpler query.
      ((analyticsConfig.interviewers.values || []).length > 0 &&
        analyticsConfig.interviewers.labels === undefined) ||
      (dataPoints.length === 0 && visibleInterviewCountQuery.loading),
    commonQueryVariables,
    benchmarkRange,
  };
};

export default useAnalyticsData;
