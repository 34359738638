import { GroupBase, StylesConfig, Theme } from "react-select";

import { useTheme as useChakraTheme } from "../components";

// react-select types are complex and difficult to use in a wrapper.
// useSelectTheme returns custom theme and style props.
// see https://react-select.com/styles
const useSelectTheme = (
  styles?: StylesConfig
): [(theme: Theme) => Theme, Record<string, any>] => {
  const theme = useChakraTheme();
  return [
    (provided) => ({
      ...provided,
      colors: {
        ...provided.colors,
        text: theme.primary,
        neutral: theme.primary,
        neutral20: theme.colors.gray[300],
        neutral50: theme.colors.gray[400],
        neutral75: theme.colors.gray[500],
        primary25: theme.colors.blue[50],
        primary50: theme.colors.blue[300],
        primary75: theme.colors.blue[400],
        primary: theme.colors.blue[500],
      },
    }),
    {
      menu: (provided: Record<string, any>) => ({
        ...provided,
        zIndex: 3,
      }),
      menuPortal: (provided: Record<string, any>) => ({
        ...provided,
        zIndex: 1500,
      }),
      control: (provided: Record<string, any>) => ({
        ...provided,
        minHeight: "40px",
        borderRadius: theme.radii.sm,
      }),
      multiValue: (provided: Record<string, any>) => ({
        ...provided,
        backgroundColor: theme.colors.blue[50],
      }),
      multiValueLabel: (provided: Record<string, any>) => ({
        ...provided,
        color: theme.colors.blue[700],
      }),
      multiValueRemove: (provided: Record<string, any>) => ({
        ...provided,
        color: theme.colors.blue[300],
        ":hover": {
          backgroundColor: theme.colors.blue[300],
          color: "white",
        },
      }),
      ...styles,
    },
  ];
};

/**
 * Identical to `useSelectTheme`, but with typed styles. Useful for type hints
 * in the provided styles
 */
export const useTypedSelectTheme = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  styles?: StylesConfig<Option, IsMulti, Group>
): [(theme: Theme) => Theme, StylesConfig<Option, IsMulti, Group>] => {
  return useSelectTheme(styles as unknown as StylesConfig);
};

export default useSelectTheme;
