import {
  Alert,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React, { memo } from "react";
import ReactMarkdown from "react-markdown";

import {
  Button,
  ExpandableTextArea,
  LoadingIndicator,
} from "../../../../components";
import useOnboardingPlanStream, {
  StreamingCompletion,
} from "./useOnboardingPlanStream";

type OnboardPocProps = {
  candidateId: string;
  positionId: string | null;
  disclosure: UseDisclosureReturn;
};

const managerTipsSubPrompt = `- helpful instructions to the hiring manager about how to help their new hire succeed in the role specifically based on the ways they like to work best, successful relationships they have had with their managers in the past; environments where they have thrived or not, etc. Include a suggestion for a manager action for each tip.`;
const areasForDevelopmentSubPrompt = `- based on feedback in any provided scorecards, or self-described areas for improvement. Helpful instructions to the hiring manager on specific areas for the new hire to focus on in terms of upskilling on a core responsibility of their job or tasks they will be responsible for. Include a suggestion for a manager action for each area for development.`;

const defaultTimelinePrompt = `Output sections:
- 30/60/90 day plan:
${managerTipsSubPrompt}
${areasForDevelopmentSubPrompt}`;

const defaultMilestonePrompt = `Output sections:
- milestones # these are not time based, but are the key achievements the new hire should aim to accomplish while ramping up. They should form a progressive series of greater accomplishments.
${managerTipsSubPrompt}
${areasForDevelopmentSubPrompt}`;

const defaultTaskPrompt = `Output sections:
- tasks # a series of onboarding tasks, based upon the role and any specific items called out in the transcripts
${managerTipsSubPrompt}
${areasForDevelopmentSubPrompt}`;

const defaultCustomPrompt = `Output sections:
- <add your sections here>
${managerTipsSubPrompt}
${areasForDevelopmentSubPrompt}`;

const OnboardingPoc: React.FC<OnboardPocProps> = ({
  candidateId,
  positionId,
  disclosure,
}) => {
  return (
    <Modal
      isOpen={disclosure.isOpen}
      closeOnOverlayClick={false}
      onClose={disclosure.onClose}
      size="3xl"
    >
      <ModalOverlay>
        <ModalContent borderRadius="24px" maxHeight="calc(100dvh - 128px)">
          <ModalHeader
            color="gray.900"
            fontWeight="600"
            fontSize="2xl"
            lineHeight="8"
            pl="6"
            pr="72px"
            pt="6"
            pb="4"
            borderBottom="1px"
            borderBottomColor="gray.100"
          >
            Onboarding Plan
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px="10" pt="0" pb="14" overflow="auto">
            <Tabs flex="1" mt={4}>
              <TabList bg="white" pl="8">
                <Tab>30 / 60 / 90 Plan</Tab>
                <Tab>Milestones</Tab>
                <Tab>Tasks</Tab>
                <Tab>Custom</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <PlanFormat
                    defaultPrompt={defaultTimelinePrompt}
                    candidateId={candidateId}
                    positionId={positionId}
                  />
                </TabPanel>
                <TabPanel>
                  <PlanFormat
                    defaultPrompt={defaultMilestonePrompt}
                    candidateId={candidateId}
                    positionId={positionId}
                  />
                </TabPanel>
                <TabPanel>
                  <PlanFormat
                    defaultPrompt={defaultTaskPrompt}
                    candidateId={candidateId}
                    positionId={positionId}
                  />
                </TabPanel>
                <TabPanel>
                  <PlanFormat
                    defaultPrompt={defaultCustomPrompt}
                    candidateId={candidateId}
                    positionId={positionId}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

type PlanFormat = {
  defaultPrompt: string;
  candidateId: string;
  positionId: string | null;
};

const PlanFormat: React.FC<PlanFormat> = ({
  defaultPrompt,
  candidateId,
  positionId,
}) => {
  const [prompt, setPrompt] = React.useState(defaultPrompt);
  const [editingPrompt, setEditingPrompt] = React.useState(false);
  const { streamingCompletion, run } = useOnboardingPlanStream(
    candidateId,
    positionId
  );
  return (
    <>
      {editingPrompt && (
        <ExpandableTextArea
          mt={10}
          mb={4}
          defaultValue={prompt}
          minHeight="15rem"
          onChange={(e) => {
            setPrompt(e.target.value);
          }}
        />
      )}
      <Flex flexDir="row" mt={10}>
        {!editingPrompt && (
          <Button
            variant="ghost"
            size="sm"
            disabled={streamingCompletion.loading}
            onClick={() => {
              setEditingPrompt(!editingPrompt);
            }}
            display="block"
          >
            Edit Prompt
          </Button>
        )}
        <Button
          ml="auto"
          size="sm"
          disabled={streamingCompletion.loading}
          onClick={() => {
            run(prompt);
          }}
          display="block"
        >
          Generate Plan
        </Button>
      </Flex>
      <PlanStreamView completion={streamingCompletion} />
    </>
  );
};

type PlanStreamViewProps = {
  completion: StreamingCompletion;
};

const PlanStreamView: React.FC<PlanStreamViewProps> = ({ completion }) => {
  if (completion.error) {
    return (
      <Alert status="error" title="Stream Error">
        An error occurred while loading the onboarding plan. Please try again.
      </Alert>
    );
  }
  if (completion.loading && !completion.text) {
    return <LoadingIndicator mt={10} />;
  }
  return <PlanView plan={completion.text || ""} />;
};

type PlanViewProps = {
  plan: string;
};

const PlanView: React.FC<PlanViewProps> = memo(({ plan }) => {
  return (
    <ReactMarkdown
      components={{
        h2(props) {
          const { node, ...rest } = props;
          return (
            <h2
              style={{
                margin: "2rem 0 0.5rem",
                fontSize: "var(--chakra-fontSizes-xl)",
                fontWeight: "600",
              }}
              {...rest}
            />
          );
        },
        h3(props) {
          const { node, ...rest } = props;
          return (
            <h3
              style={{
                margin: "1rem 0 0.5rem",
                fontSize: "var(--chakra-fontSizes-lg)",
                fontWeight: "600",
              }}
              {...rest}
            />
          );
        },
        h4(props) {
          const { node, ...rest } = props;
          return (
            <h4
              style={{
                margin: "0.5rem 0",
                fontSize: "var(--chakra-fontSizes-md)",
                fontWeight: "600",
              }}
              {...rest}
            />
          );
        },
        p(props) {
          const { node, ...rest } = props;
          return <p style={{ margin: "0" }} {...rest} />;
        },
        hr(_) {
          return <span />;
        },
        li(props) {
          const { node, ...rest } = props;
          return <li style={{ margin: "0" }} {...rest} />;
        },
        ul(props) {
          const { node, ...rest } = props;
          return <ul style={{ marginLeft: "1rem" }} {...rest} />;
        },
        ol(props) {
          const { node, ...rest } = props;
          return <ol style={{ marginLeft: "1rem" }} {...rest} />;
        },
      }}
    >
      {plan}
    </ReactMarkdown>
  );
});

export default OnboardingPoc;
