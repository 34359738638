import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

import { SelectDateRangeState } from "../../../../components/SelectDateRange/SelectDateRange";
import { METRIC_ENFORCED_DOMAINS, METRIC_RECOMMENDED_VALUES } from "../const";
import AnalyticsLineChart from "../line-chart/AnalyticsLineChart";
import { ReportMetric } from "../types";
import {
  formatAnalyticsDateRange,
  getLineChartData,
  getValidDataForMetric,
} from "../utils";
import { OverviewData } from "./useOverviewData";

type OverviewMetricLineChartProps = {
  overviewData: OverviewData;
  metric: ReportMetric;
  dateRangeState: SelectDateRangeState;
  benchmarkRange?: {
    upperBound: number;
    lowerBound: number;
  };
};

const OverviewMetricLineChart: React.FC<OverviewMetricLineChartProps> = ({
  overviewData,
  metric,
  dateRangeState,
  benchmarkRange,
}) => {
  const validData = getValidDataForMetric(
    overviewData.baseData,
    metric.rawDataKey
  );

  const recommendedValue = METRIC_RECOMMENDED_VALUES[metric.metric];
  const domain = METRIC_ENFORCED_DOMAINS[metric.metric];

  return validData.length > 0 ? (
    <AnalyticsLineChart
      data={getLineChartData(
        overviewData.baseData,
        overviewData.comparisonData || []
      )}
      rangeDisplayValues={formatAnalyticsDateRange(dateRangeState)}
      columnsConfig={overviewData.columnsConfig}
      bucketSize={overviewData.bucketSize}
      bucketInterval={overviewData.bucketInterval}
      dataKey={`datum.${metric.rawDataKey}`}
      yAxis={{
        formatter: (value) =>
          metric.format
            ? `${metric.format(value)}${metric.units || ""}`
            : value.toString(),
        tickCount: 2,
        domain,
      }}
      line={
        typeof recommendedValue === "number"
          ? {
              label: "Recommended",
              value: recommendedValue,
            }
          : undefined
      }
      tooltipVersion="myInsights"
      benchmarkRange={benchmarkRange}
      aspect={1.8}
      labelReferenceInLegend
      drawDot={validData.length === 1}
      tickStrategy="firstAndLast"
    />
  ) : (
    <Flex direction="column" align="center">
      <Image src="/static/images/analytics_no_report_data.svg" maxW="112px" />
      <Box color="gray.600" fontWeight="600" fontSize="md">
        <Text>No data available.</Text>
      </Box>
    </Flex>
  );
};

export default OverviewMetricLineChart;
