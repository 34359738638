import { useCandidateSummaryPositionPermissionsQuery } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import { useCandidateSummaryFullyEnabled } from "./useCandidateSummaryFullyEnabled";

export const useShowEditCandidateSummaryTemplate = (
  positionId: string | null
): boolean => {
  const fullyFlagEnabled = useCandidateSummaryFullyEnabled();
  const uiFlagEnabled = useFeatureFlag("candidate_summary:ui");
  const editFlagEnabled = useFeatureFlag("candidate_summary:edit");
  const specificallyFlagEnabled = uiFlagEnabled && editFlagEnabled;
  const flagEnabled = fullyFlagEnabled || specificallyFlagEnabled;

  const { data: permissions } = useCandidateSummaryPositionPermissionsQuery({
    variables: {
      positionId,
    },
  });
  const canEditTemplates = Boolean(
    permissions?.candidateSummaryPositionPermissions?.canEditTemplates
  );

  return flagEnabled && canEditTemplates;
};
