import { Icon } from "@chakra-ui/react";
import React from "react";
import { FiSearch } from "react-icons/fi";
import Select, { components, ControlProps } from "react-select";
import CreatableSelect from "react-select/creatable";

import { useTheme } from "../../../components";
import { useTypedSelectTheme } from "../../../hooks/useSelectTheme";
import { email as emailRegex } from "../../../utils/regex";
import { isInternalUser, ShareModalProps, ShareUser } from "./types";

type ShareOption = { label: string; value: ShareUser };

type SelectShareUsersProps = Pick<
  ShareModalProps,
  | "canShareExternal"
  | "optionsLoading"
  | "shareableUsers"
  | "maxSelection"
  | "onSelection"
> & {
  selectedUsers: ShareUser[];
};

const Control: React.FC<ControlProps<ShareOption, true>> = ({
  children,
  ...props
}) => {
  const { hasValue } = props;

  return (
    <components.Control {...props}>
      {!hasValue && (
        <Icon as={FiSearch} w="5" h="5" color="gray.500" ml="4" mr="1" />
      )}
      {children}
    </components.Control>
  );
};

export const SelectShareUsers: React.FC<SelectShareUsersProps> = ({
  canShareExternal,
  optionsLoading,
  shareableUsers = [],
  selectedUsers,
  maxSelection = 10,
  onSelection,
}) => {
  const { colors } = useTheme();
  const [selectTheme, selectStyles] = useTypedSelectTheme<ShareOption, true>({
    multiValue: (base, props) => ({
      ...base,
      backgroundColor: isInternalUser(props.data.value)
        ? colors.blue[50]
        : colors.orange[100],
    }),
    multiValueLabel: (base, props) => ({
      ...base,
      color: isInternalUser(props.data.value)
        ? colors.blue[700]
        : colors.gray[800],
    }),
    multiValueRemove: (base, props) => ({
      ...base,
      color: isInternalUser(props.data.value)
        ? colors.blue[300]
        : colors.orange[300],
    }),
  });

  const getUserValue = (user: ShareUser): ShareOption => ({
    label: isInternalUser(user)
      ? `${user.fullName} - ${user.email}`
      : user.email,
    value: user,
  });

  const SelectComponent = canShareExternal ? CreatableSelect : Select;

  return (
    <SelectComponent
      aria-label="share-select-input"
      data-testid="share-select"
      theme={selectTheme}
      styles={selectStyles}
      isClearable
      components={{
        DropdownIndicator: null,
        Control,
      }}
      isMulti
      placeholder="Invite a colleague to view"
      autoFocus
      isLoading={optionsLoading}
      value={selectedUsers.map(getUserValue)}
      noOptionsMessage={() =>
        canShareExternal ? "Enter an email address" : "No users found"
      }
      isValidNewOption={(input) => !!input.trim().match(emailRegex)}
      getNewOptionData={(email) => ({
        label: `Share with "${email}"`,
        value: { id: email, email },
      })}
      options={
        selectedUsers.length < maxSelection
          ? shareableUsers.map(getUserValue)
          : []
      }
      onChange={(selectedOptions) => {
        if (!selectedOptions) {
          onSelection?.([]);
          return;
        }
        const selectedIds = new Set();
        onSelection?.(
          selectedOptions
            .map(({ value }) => value)
            .filter((user) => {
              if (selectedIds.has(user.id)) return false;
              selectedIds.add(user.id);
              return true;
            })
        );
      }}
    />
  );
};
